
.DialogBox_Div {
    width: 330px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    position: fixed;
    left: 40%;
    top: 75%;
    transform: translate(-50%,-50%);
    z-index: 999;
    background-color: #eee;
    padding: 10px 20px 40px;
    padding: 10px;
    padding-top: 5px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.P_Style {
    font-size: 0.9rem;
}

@media (max-width: 600px) {
    .DialogBox_Div {
        position: fixed;
        left: 50%;
        top: 75%;
        height: 206px;
    }
    .P_Style {
        font-size: 0.9rem;
    }
}