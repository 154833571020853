@import "../MainCSS.css";

.Dec6_JoinUs_FEB_Block {
    width: 80%;
    display: grid;
    justify-content: space-evenly;
    grid-template-rows: 300px 300px;
    grid-template-columns: repeat(2,1fr);
    margin:50px auto;
    row-gap: 30px;
    column-gap: 0px;
    text-align: center;
    font-family: var(--fontfamily); 
}

.Dec6_JoinUs_FEB_Block_Box1 {
    border: 1px;
 /*   border-radius: 5%; */
    background-image: url("../Images/apprentices.jpg");  
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 520px;
}
.Dec6_JoinUs_FEB_Block_Box2 {
    border: 1px solid #BFBFBF;
    grid-column: 2/4;
    background-color: var(--whitecolor);
 /*   border-radius: 1%; */
 /*   background-image: url("../Images/Team.jpg");  */
    background-repeat: no-repeat;
    background-size: cover;
  /*  background: linear-gradient(white, #cc0000); */
    
}
.Dec6_JoinUs_FEB_Block_Box3 {
    border: 1px solid  #BFBFBF;
    grid-column: 1/3;
    background-color: var(--whitecolor);
    /*   box-shadow: 10px 10px 5px #aaaaaa;
    box-shadow: 5px 10px 20px var(--redcolor) inset;  
    background: radial-gradient(white, #cc0000);  */
}
.Dec6_JoinUs_FEB_Block_Box4 {
    border: 1px ;
 /*   border-radius: 5%;  */
    background-image: url("../Images/Graduates.jpg");  
    
    background-repeat: no-repeat;
    background-size: cover;
    width: 520px;
}

.Dec6_JoinUs_FEB_Block_Box_H {
    font-size: 1.5rem;
    font-weight: 900;
    text-align: left;
    padding-top: 50px;
    color: var(--redcolor);
    border-radius: 5%;
    padding-left: 20px;
}

.Dec6_JoinUs_FEB_Block_Box_P {
    padding-top: 20px;
    color: var(--blackcolor);
    text-align: left;
    padding-left: 20px;
    font-weight: 0.85rem;
    padding-right: 25px;
}

.Dec6_JoinUs_FEB_Block_Box_Button1 {
    font-size: 1rem;
    font-weight: 900;
    width: 230px;
    height: 50px;
    text-align: center;
    color: var(--whitecolor);
    background-color: var(--redcolor);
    margin-top: 30px;
    border: none;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 1080px) {
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 420px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 420px;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 890px) {
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 380px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 380px;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1.5rem;
        font-weight: 900;
        text-align: left;
        padding-top: 30px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 20px;
    }
    
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 20px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 20px;
        font-weight: 0.85rem;
        padding-right: 25px;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 800px) {
    .Dec6_JoinUs_FEB_Block {
        width: 80%;
        display: grid;
        justify-content: space-evenly;
        grid-template-rows: 250px 300px;
        grid-template-columns: repeat(2,1fr);
        margin:auto;
        row-gap: 30px;
    }
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 350px;
        height: 250px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 350px;
        height: 250px;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1.3rem;
        font-weight: 900;
        text-align: left;
        padding-top: 20px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 20px;
    }
    
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 20px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 20px;
        font-size: 0.85rem;
        padding-right: 25px;
    }
}

@media (max-width: 800px) {
    .Dec6_JoinUs_FEB_Block {
        width: 80%;
        display: grid;
        justify-content: space-evenly;
        grid-template-rows: 200px 250px;
        grid-template-columns: repeat(2,1fr);
        margin:auto;
        row-gap: 30px;
    }
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 260px;
        height: 200px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        height: 198px;
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
        height: 198px;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 260px;
        height: 200px;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1.1rem;
        font-weight: 900;
        text-align: left;
        padding-top: 10px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 20px;
    }
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 10px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 20px;
        font-size: 0.75rem;
        padding-right: 25px;
    }
    .Dec6_JoinUs_FEB_Block_Box_Button1 {
        width: 170px;
        margin-top: 10px;
        font-size: 0.9rem;
        font-weight: 500;
        height: 35px;
    }
}

@media (max-width: 560px) {
    .Dec6_JoinUs_FEB_Block {
        width: 80%;
        display: grid;
        justify-content: space-evenly;
        grid-template-rows: 200px 250px;
        grid-template-columns: repeat(2,1fr);
        margin:auto;
        row-gap: 30px;
    }
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 150px;
        height: 200px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        height: 198px;
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
        height: 198px;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 150px;
        height: 200px;
        background-position: center;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1.1rem;
        font-weight: 900;
        text-align: left;
        padding-top: 10px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 20px;
    }
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 10px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 20px;
        font-size: 0.75rem;
        padding-right: 25px;
    }
    .Dec6_JoinUs_FEB_Block_Box_Button1 {
        width: 170px;
        margin-top: 10px;
        font-size: 0.9rem;
        font-weight: 500;
        height: 32px;
    }
}

@media (max-width: 530px) {
    .Dec6_JoinUs_FEB_Block {
        width: 90%;
        display: grid;
        justify-content: space-evenly;
        grid-template-rows: 250px 300px;
        grid-template-columns: repeat(2,1fr);
        margin:auto;
        row-gap: 30px;
    }
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 150px;
        height: 250px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 150px;
        height: 250px;
        background-position: center;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1rem;
        font-weight: 900;
        text-align: left;
        padding-top: 10px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 20px;
    }
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 10px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 20px;
        font-size: 0.75rem;
        padding-right: 25px;
    }
    .Dec6_JoinUs_FEB_Block_Box_Button1 {
        width: 170px;
        margin-top: 10px;
        font-size: 0.9rem;
        font-weight: 500;
        height: 32px;
    }
}

@media (max-width: 400px) {
    .Dec6_JoinUs_FEB_Block {
        width: 100%;
        display: grid;
        justify-content: space-evenly;
        grid-template-rows: 250px 300px;
        grid-template-columns: repeat(2,1fr);
        margin:auto;
        row-gap: 30px;
    }
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 150px;
        height: 250px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 150px;
        height: 250px;
        background-position: center;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1rem;
        font-weight: 900;
        text-align: left;
        padding-top: 10px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 10px;
    }
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 10px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 10px;
        font-size: 0.75rem;
        padding-right: 25px;
    }
    .Dec6_JoinUs_FEB_Block_Box_Button1 {
        width: 170px;
        margin-top: 10px;
        font-size: 0.9rem;
        font-weight: 500;
        height: 32px;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 360px) {
    .Dec6_JoinUs_FEB_Block {
        width: 100%;
        display: grid;
        justify-content: space-evenly;
        grid-template-rows: 250px 300px;
        grid-template-columns: repeat(2,1fr);
        margin:auto;
        row-gap: 30px;
    }
    .Dec6_JoinUs_FEB_Block_Box1 {
        width: 130px;
        height: 250px;
    }
    .Dec6_JoinUs_FEB_Block_Box2 {
        border: 1px solid #BFBFBF;
        grid-column: 2/4;
        background-color: var(--whitecolor);
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box3 {
        border: 1px solid  #BFBFBF;
        grid-column: 1/3;
        height: 248px;
    }
    .Dec6_JoinUs_FEB_Block_Box4 {
        width: 130px;
        height: 250px;
        background-position: center;
    }
    .Dec6_JoinUs_FEB_Block_Box_H {
        font-size: 1rem;
        font-weight: 900;
        text-align: left;
        padding-top: 10px;
        color: var(--redcolor);
        border-radius: 5%;
        padding-left: 10px;
    }
    .Dec6_JoinUs_FEB_Block_Box_P {
        padding-top: 10px;
        color: var(--blackcolor);
        text-align: left;
        padding-left: 5px;
        font-size: 0.75rem;
        padding-right: 25px;
    }
    .Dec6_JoinUs_FEB_Block_Box_Button1 {
        width: 150px;
        margin-top: 10px;
        font-size: 0.85rem;
        font-weight: 500;
        height: 32px;
        margin-left: -10px;
    }
}

@media (max-width: 450px) {
}