@import "../MainCSS.css";
/*
html{
    font-size: 62.5%;
  
    /* 1rem = 16px
       16px = 100%
       10px = 62.5%
       1rem = 10px
    */
.Dec6_PYG_Grid {
    max-width: 100%;
}
.Dec6_Home_PYG_Container {
    margin-top: 110px;
    background-color: transparent;
    font-family:  var(--fontfamily);
    color: var(--whitecolor);
    height: 450px;
    max-width: 100%;
    text-align: center;    
    z-index: 2;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8)) ;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
/*
.Dec6_Home_PYG_Container_BGColor {
    height: 150px;
    width: 600px;
    background-color: black;
    opacity: 0.8;
    z-index: 2;
    position: absolute;
    top: 370px;
    left: 280px;
}*/
.Dec6_Home_PYG_Container_H1{
    font-size: 3.8rem;
    padding-top: 170px;
    font-weight: 700;
 /*   margin-left: 420px; */
}
.Dec6_Home_PYG_Container_H2{
    font-size: 2rem;
    z-index: 2;
    font-weight: 500;
    padding-top: 30px;
    padding-top: 26px;
}


/* big-landscape tablets, low resolution laptop and desktop */
@media screen and (max-width: 1200px) {
    .Dec6_Home_PYG_Container {
        margin-top: 86.4px;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media screen and (max-width: 990px) {
    .Dec6_Home_PYG_Container{
        width: 100%;
    }
}

/* portrait tablet, e-readers kindle */
@media screen and (max-width: 700px) {
    .Dec6_Home_PYG_Container_H1{
        font-size: 3.2rem;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 1.7rem;
    }
}

/* smartphones, landscape i-phone */
@media screen and (max-width: 600px) {
    .Dec6_Home_PYG_Container {
        background-color: transparent;
        font-family:  var(--fontfamily);
        color: var(--whitecolor);
        height: 350px;
        width: 100%;
        background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8)), url("../Images/PropelYourGrowth.jpg") ;
        background-size: cover;
        background-position: top center;
    }
    .Dec6_Home_PYG_Container_H1{
        font-size: 2.95rem;
        padding-top: 150px;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 1.6rem;
    }
}

/* portrait smartphones, portrait i-phone */
@media screen and (max-width: 540px) {
    .Dec6_Home_PYG_Container_H1{
        font-size: 2.7rem;
        padding-top: 150px;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 500px) {
    .Dec6_Home_PYG_Container_H1{
        font-size: 2.3rem;
        padding-top: 150px;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 450px) {
    .Dec6_Home_PYG_Container {
        background-color: transparent;
        font-family:  var(--fontfamily);
        color: var(--whitecolor);
        height: 350px;
        width: 100%;
        background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.8)), url("../Images/PropelYourGrowth.jpg") ;
        background-size: cover;
        background-position: top center;
    }
    .Dec6_Home_PYG_Container_H1{
        font-size: 2rem;
        padding-top: 140px;
        font-weight: 700;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 1rem;
        z-index: 2;
        font-weight: 500;
        padding-top: 26px;
    }
}

@media screen and (max-width: 400px) {
    .Dec6_Home_PYG_Container_H1{
        font-size: 1.8rem;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 1rem;
    }
}

@media screen and (max-width: 350px) {
    .Dec6_Home_PYG_Container_H1{
        font-size: 1.6rem;
        font-weight: 700;
    }
    .Dec6_Home_PYG_Container_H2{
        font-size: 0.93rem;
    }
}
