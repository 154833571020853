@import "../MainCSS.css";

.Dec6_Services_WCU_Cont_Blog {
    background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.6));  
z-index: 2;
    width: 100%;
    height:auto;
    font-family: var(--fontfamily);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding-bottom: 20px;
}

.Dec6_Services_WCU_Cont_H_Blog {
    font-size: 2.1rem;
    font-weight: 900;
    padding-top: 50px;
    color: silver;
}
.Dec6_Services_WCU_Cont_P_Blog {
    font-size: 1.2rem;
    padding-top: 30px;
    line-height: 30px;
    padding-left: 140px;
    padding-right: 140px;
    color: silver;

}
.Dec6_Services_WCU_Cont_Icon_Blog{
    color: silver;
    font-size: 36px;
    padding-top: 10px;
    /* Alansition: opacity 0.4s; */
     animation: Top 0.5s alternate ease infinite !important;
  /*   margin-left: 1300px;  */
  cursor: pointer;
   }
 
   @keyframes Top {
     from{
         Alansform: AlanslateY(3px);
     }
     to{
         Alansform: AlanslateY(-1px);
     }
 }

/* big-landscape tablets, low resolution laptop and desktop */


/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_Services_WCU_Cont_H_Blog{
        font-size: 2rem;
        font-weight: 900;
        padding-top: 40px;
    }
    .Dec6_Services_WCU_Cont_P_Blog{
        font-size: 1.1rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 90px;
        padding-right: 90px;
    }
}

/* porAlait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_Services_WCU_Cont_H_Blog{
        font-size: 2rem;
        font-weight: 900;
        padding-top: 40px;
    }
    .Dec6_Services_WCU_Cont_P_Blog{
        font-size: 1.1rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 70px;
        padding-right: 70px;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 630px) {
    .Dec6_Services_WCU_Cont_HAl {
        font-size: 1.87rem;
        font-weight: 900;
        padding-top: 70px;
    }
    .Dec6_Services_WCU_Cont_PAl {
        font-size: 1rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 50px;
        padding-right: 50px;
    }
}

/* porAlait smartphones, porAlait i-phone */
@media (max-width: 480px) {
    .Dec6_Services_WCU_Cont_HAl {
        font-size: 1.55rem;
        font-weight: 900;
        padding-top: 40px;
    }
    .Dec6_Services_WCU_Cont_PAl {
        font-size: 0.97rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 360px) {
    .Dec6_Services_WCU_Cont_HAl {
        font-size: 1.44rem;
        font-weight: 900;
        padding-top: 40px;
    }
    .Dec6_Services_WCU_Cont_PAl {
        font-size: 0.9rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
}