@import "../MainCSS.css";

.Dec6_Navbar_Items1_List2 {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    padding-top: 25px;
    /* padding-right: 80px; */
    font-family: var(--fontfamily);
    margin-left: 200px;
}
.Dec6_Navbar_Items1_List2 li {
    padding: 24px 20px;
}
.name_menu{
    top: 45px !important;
    left: -50px !important;

}
.button-name{
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
}
.btn-d{
    font-size:25px !important;
    cursor:pointer !important;
    padding-top:0px !important;
    padding-bottom: 4px !important;
}
.select{
    display: inline-flex !important;
    /* webkit-transform: rotate(
45deg); */
 -webkit-transform: rotate(0deg) !important;
 background-color: transparent !important;

}
.Dec6_Navbar_Items1_List2 li a{
    text-decoration: none;
    color: black;
    font-size: 1.1rem;

    transition: color 0.3sec;

}
.Dec6_Navbar_Items1_List2 li a:hover {
    color: var(--redcolor);
  }
/*
  .Dec6_Navbar_Items1_List2 li a:active {
    color: var(--blackcolor);
    text-decoration: underline;
  }  */

  .Dec6_Navba1 .Dec6_Navbar_Items1_List2 .active {
    color:Black ;
 /*   text-decoration: underline;
    text-decoration-color: var(--redcolor);
    text-decoration-thickness: 2px;
    text-underline-position: below;
    margin-top: 20px;  */

    border-bottom: 2px solid var(--redcolor);
    /* padding: 8px 0px; */
    padding-bottom: 8px;
 }

  /*
@media screen and (max-width: 768px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
        flex-flow: column nowrap;
        background-color: black;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 250px;
        padding-top: 7.5rem;
    }
    .Dec6_Navbar_Items1_List2 li a {
        color: white;
    }
}
*/

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1320px) {
    .Dec6_Navbar_Items1_List2 li a{
        font-size: 0.9rem;
    }
}
@media (max-width: 1230px) {
    .Dec6_Navbar_Items1_List2 li a{
        font-size: 0.85rem;
    }
}
@media (max-width: 1210px) {
    .Dec6_Navbar_Items1_List2 li a{
        font-size: 0.8rem;
    }
}

@media (max-width: 1200px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
    }
}

@media (max-width: 450px) {
    .Dec6_Navbar_Items1_List2{
        display: none;
    }
}