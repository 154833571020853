@import "../MainCSS.css";

.Dec6_JoinUs_BgImage_Block {
    width: 100%;
    height: 500px;
    background-image: url('../Images/joinus_exped.jpg');
    margin-top: 110px;
    background-position: bottom ;
    background-attachment: fixed;
    font-family: var(--fontfamily);
 /*   text-align: center;  */
}

.Dec6_JoinUs_BgImage_Block_Line {
    width: 510px;
    height: 140px;
/*    margin-top: 260px;  */
    display: inline-block;
    background-color: white;
 /*   position: absolute; */
    margin-left: 90px;  
    margin-top: 260px;
    opacity: 0.75;
    border-top: 3px solid var(--redcolor);
    text-align: center;
}

.Dec6_JoinUs_BgImage_Block_Line_H {
    font-size: 2.85rem;
    color: var(--redcolor);
    padding-top: 10px;  
    font-weight: 500;
  /*  padding-left: 550px; */
}

.Dec6_JoinUs_BgImage_Block_Line_P {
    font-size: 1.2rem;
    color: var(--blackcolor);
 /*   padding-top: 330px;  */
    font-weight: 500;
    padding-top: 15px;
  /*  padding-left: 550px; */
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    .Dec6_JoinUs_BgImage_Block {
        margin-top: 86.4px;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
      .Dec6_JoinUs_BgImage_Block_Line {
        width: 390px;
        height: 140px;
        margin-left: 30px;
    }
    .Dec6_JoinUs_BgImage_Block_Line_H {
        font-size: 2.4rem;
    }
    .Dec6_JoinUs_BgImage_Block_Line_P {
        font-size: 1.1rem;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_JoinUs_BgImage_Block_Line {
        width: 320px;
        height: 130px;
        margin-left: 20px;
    }
    .Dec6_JoinUs_BgImage_Block_Line_H {
        font-size: 2.1rem;
    }
    .Dec6_JoinUs_BgImage_Block_Line_P {
        font-size: 1.1rem;
    }
}

@media (max-width: 640px) {
    .Dec6_JoinUs_BgImage_Block {
      text-align: center;
  }
  .Dec6_JoinUs_BgImage_Block_Line {
      width: 80vw;
      height: 18vh;
      margin-top: 340px;
  }
  .Dec6_JoinUs_BgImage_Block_Line_H {
      font-size: 1.8rem;
  }
  .Dec6_JoinUs_BgImage_Block_Line_P {
      font-size: 1.05rem;
  }
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    
}

@media (max-width: 540px) {
    .Dec6_JoinUs_BgImage_Block {
        text-align: center;
    }
    .Dec6_JoinUs_BgImage_Block_Line {
        width: 80vw;
        height: 18vh;
        margin-top: 340px;
    }
    .Dec6_JoinUs_BgImage_Block_Line_H {
        font-size: 1.7rem;
    }
    .Dec6_JoinUs_BgImage_Block_Line_P {
        font-size: 1rem;
    }
}
/* portrait smartphones, portrait i-phone */
@media (max-width: 380px) {
    .Dec6_JoinUs_BgImage_Block {
        text-align: center;
    }
    .Dec6_JoinUs_BgImage_Block_Line {
        width: 80vw;
        height: 20vh;
        margin-top: 320px;
    }
    .Dec6_JoinUs_BgImage_Block_Line_H {
        font-size: 1.5rem;
    }
    .Dec6_JoinUs_BgImage_Block_Line_P {
        font-size: 0.95rem;
    }    
}

@media (max-width: 450px) {
}