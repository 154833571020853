@import "../MainCSS.css";

* {
    margin: 0;
    padding: 0;
}
.Dec6_Navbar_Items1 {
    display: flex;
 /*   justify-content: space-between; */
    position: fixed;  
    width: 100%;  
    height: 104px; 
    background: white;
    z-index: 4;  
    border-bottom: 1px solid #c3c1c1;
    border-top: 5px solid var(--redcolor);
}
.Dec6_Navbar_Items1_Image {
  /*  width: 180px;  */
    height: 60px; 
    margin: 22px 140px;
}

.Dec6_Navbar_Items1.active {
    background-color: var(--whitecolor) ;
    color: var(--blackcolor) !important;
    margin-top: -1px;
  }

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    .Dec6_Navbar_Items1 { 
        height: 80px; 
    }
    .Dec6_Navbar_Items1_Image_Div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .Dec6_Navbar_Items1_Image {
          margin: 18px 30px;
          height: 45px;
      }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {

}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
}

@media (max-width: 768px) {
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
}

@media (max-width: 450px) {
}

@media (max-width: 360px) {
}