@import "../MainCSS.css";

.Dec6_AboutUs_Vision_Block {
    margin-top: 10px;
    width: 100%;
    height: 400px;
    background: url("../Images/Industries2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    font-family: var(--fontfamily);
}
.Dec6_AboutUs_Vision_Block_Cont {
  /*  position: absolute;
    top: 80px;
    left: 50px;  */
    display: inline-block;
    margin-left: 30px;
    margin-top: 110px;
    width: 400px;
    height: 170px;
    border-top: 5px solid #cc0000;
    background: linear-gradient(rgb(255,255,255));
    background-color: var(--whitecolor);
    opacity: 0.6;
    z-index: 2;
    padding-left: 20px;
}
.Dec6_AboutUs_Vision_Block_Cont_MH {
    font-size: 1.55rem;
    color: var(--blackcolor);
    padding-top: 13px;
}
.Dec6_AboutUs_Vision_Block_Cont_P {
    font-size: 1.1rem;
    color: var(--blackcolor);
    padding-top: 18px;
    padding-right: 13px;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_AboutUs_Vision_Block_Cont {
          width: 320px;
          height: 145px;
      }
      .Dec6_AboutUs_Vision_Block_Cont_MH {
          font-size: 1.2rem;
      }
      .Dec6_AboutUs_Vision_Block_Cont_P {
          font-size: 0.95rem;
      }
}

@media (max-width: 740px) {
    .Dec6_AboutUs_Vision_Block_Cont {
          width: 260px;
          height: 170px;
          margin-top: 150px;
          padding-left: 10px;
      }
      .Dec6_AboutUs_Vision_Block_Cont_MH {
          font-size: 1.2rem;
      }
      .Dec6_AboutUs_Vision_Block_Cont_P {
          font-size: 0.95rem;
      }
}

/* smartphones, landscape i-phone */
@media (max-width: 620px) {
    .Dec6_AboutUs_Vision_Block_Cont {
        width: 240px;
        height: 170px;
        margin-top: 150px;
        padding-left: 10px;
        margin-left: 10px;
    }
    .Dec6_AboutUs_Vision_Block_Cont_MH {
        font-size: 1.1rem;
    }
    .Dec6_AboutUs_Vision_Block_Cont_P {
        font-size: 0.9rem;
    }
}

@media (max-width: 510px) {
    .Dec6_AboutUs_Vision_Block_Cont {
        width: 210px;
        height: 190px;
        margin-top: 170px;
        padding-left: 10px;
        margin-left: 10px;
    }
    .Dec6_AboutUs_Vision_Block_Cont_MH {
        font-size: 1.1rem;
    }
    .Dec6_AboutUs_Vision_Block_Cont_P {
        font-size: 0.9rem;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 450px) {
}