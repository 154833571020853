.home_background{
    width:100%;
    /* margin-top: 50px; */
    max-width: 100%;
    /* text-align: center;     */
    z-index: 2;
    background:  url("../../components/Images/CU3.jpg") ;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}