@import "../MainCSS.css";

.Dec6_Services_WCU_Cont {
    width: 100%;
    height: 480px;
    font-family: var(--fontfamily);
    background-color: var(--blackcolor);
    background-image:linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url('../Images/Services.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color : var(--whitecolor);
    text-align: center;
    margin-top: 110px;
}

.Dec6_Services_WCU_Cont_H {
    font-size: 2.1rem;
    font-weight: 900;
    padding-top: 120px;
}
.Dec6_Services_WCU_Cont_P {
    font-size: 1.2rem;
    padding-top: 30px;
    line-height: 30px;
    padding-left: 140px;
    padding-right: 140px;
}
.Dec6_Services_WCU_Cont_Icon {
    color: white;
    font-size: 30px;
    padding-top: 30px;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    .Dec6_Services_WCU_Cont {
        margin-top: 86.4px;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_Services_WCU_Cont_H {
        font-size: 2rem;
        font-weight: 900;
        padding-top: 100px;
    }
    .Dec6_Services_WCU_Cont_P {
        font-size: 1.1rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 90px;
        padding-right: 90px;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_Services_WCU_Cont_H {
        font-size: 2rem;
        font-weight: 900;
        padding-top: 100px;
    }
    .Dec6_Services_WCU_Cont_P {
        font-size: 1.1rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 70px;
        padding-right: 70px;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 630px) {
    .Dec6_Services_WCU_Cont_H {
        font-size: 1.87rem;
        font-weight: 900;
        padding-top: 70px;
    }
    .Dec6_Services_WCU_Cont_P {
        font-size: 1rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 50px;
        padding-right: 50px;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    .Dec6_Services_WCU_Cont_H {
        font-size: 1.55rem;
        font-weight: 900;
        padding-top: 60px;
    }
    .Dec6_Services_WCU_Cont_P {
        font-size: 0.97rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 360px) {
    .Dec6_Services_WCU_Cont_H {
        font-size: 1.44rem;
        font-weight: 900;
        padding-top: 50px;
    }
    .Dec6_Services_WCU_Cont_P {
        font-size: 0.9rem;
        padding-top: 30px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
}