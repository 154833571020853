@import "../MainCSS.css";

.Dec6_AboutUs_Mission_Block {
    margin-top: 10px;
    width: 100%;
    height: 28vh;
    z-index: -1;
    font-family: var(--fontfamily);
    align-items: center;
    text-align: center;
}
.Dec6_AboutUs_Mission_Block_Cont {
 /*   position: absolute;
    top: 10px;
    left: 230px;*/
 /*   margin-left: 190px;*/
    display: inline-block;
    width: 71vw;
    height: 26vh;
    border: 1px solid #BFBFBF;
    border-top: 2px solid var(--redcolor);
    border-left: 2px solid var(--redcolor);
    box-shadow:10px 10px 5px #aaaaaa;
    background: linear-gradient(rgb(255,255,255));
    background-color: var(--whitecolor);
    z-index: 2;
    text-align: center;
}
.Dec6_AboutUs_Mission_Block_Cont_MH {
    font-size: 1.6rem;
    color: var(--redcolor);
    padding-top: 20px;
}
.Dec6_AboutUs_Mission_Block_Cont_P {
    font-size: 1.05rem;
    color: var(--blackcolor);
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_AboutUs_Mission_Block_Cont {
           width: 78vw;
           height: 26vh;
       }
    .Dec6_AboutUs_Mission_Block_Cont_MH {
        font-size: 1.4rem;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        font-size: 0.98rem;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 660px) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 78vw;
        height: 29vh;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 590px) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 84vw;
        height: 29vh;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 500px) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 32vh;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 450px) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 32vh;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Dec6_AboutUs_Mission_Block_Cont_MH {
        font-size: 1.2rem;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        font-size: 0.9rem;
    }
}

/* Galaxy S5 */
@media screen 
  and (device-width: 320px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 32vh;
    }
}

/* IPhone 5S */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) 
  {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 38vh;
        margin-bottom: 30px;
    }
  }

  /* Galaxy S5 */
@media screen 
and (device-width: 320px) 
and (device-height: 640px) 
and (-webkit-device-pixel-ratio: 3) {
  .Dec6_AboutUs_Mission_Block_Cont {
      width: 88vw;
      height: 32vh;
  }
}

/* IPhone 6,7,8 */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 30vh;
        margin-bottom: 30px;
    }
}

  /* IPhone X */
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) 
   {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 27vh;
        margin-bottom: 30px;
    }
   }

   /* IPad Pro */
   @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 23vh;
        margin-bottom: 30px;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        padding-left: 20px;
        padding-right: 20px;
    }
    .Dec6_AboutUs_Mission_Block_Cont_MH {
        font-size: 1.7rem;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        font-size: 1.3rem;
    }
}

/* IPad Prooo */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .Dec6_AboutUs_Mission_Block_Cont {
        width: 88vw;
        height: 21vh;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        padding-left: 40px;
        padding-right: 40px;
    }
    .Dec6_AboutUs_Mission_Block_Cont_MH {
        font-size: 2rem;
    }
    .Dec6_AboutUs_Mission_Block_Cont_P {
        font-size: 1.5rem;
    }
}