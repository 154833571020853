@import "../MainCSS.css";

.Dec6_ContactUs_4BS_MBlock_BGColor {
    width: 100%;
    height: 430px;
    margin-top: 5px;
    background-color: #f7f7f7;
}
.Dec6_ContactUs_4BS_MBlock {
    display: grid;
    width: 95%;
    margin: 10px auto;
    
  /*  margin-top: 160px; */
    grid-template-rows: 50px 70px 300px;
    grid-template-rows: 400px;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, (4,1fr));
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-columns: 300px 300px 300px 300px;
    font-family: var(--fontfamily);
    gap: 15px;
}

.Dec6_ContactUs_4BS_MBlock_Grid {
    margin-bottom: 30px;
}

.Dec6_ContactUs_4BS_MBlock_Cont_Paper {
    max-width: 320px; 
    min-width: 285px;  
    width: 100%;
    height: 350px;
 /*   margin-top: 20px;    */
 /*   margin-left: -20px;   */
    margin: 20px auto;
    border-top: 6px solid var(--redcolor);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H {
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: bold;
    padding-left: 20px;
    padding-top: 8px;
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH {
    margin-top: 240px;
    font-size: 1.1rem;
    
    padding-left: 20px;
    color: var(--greycolorshade);
    color: var(--redcolor);
    font-weight: 500;
}

.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link {
 /*   display: inline-block;
    padding-top: 220px;  */
    font-size: 1.1rem;
    color: var(--redcolor);
    font-weight: 500;
    font-size: 1.15rem;
  /*  padding-left: 20px;  */
    text-decoration: none;
    transition: transform(2sec);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link:hover {
    color: var(--greycolorshade);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH1 {
    margin-top: 160px;
    font-size: 1.15rem;
    
    color: slategray;
    color: var(--greycolorshade);
    color: var(--redcolor);
    font-weight: 500;
    padding-left: 20px;
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH2 {
    padding-top: 5px;
    font-size: 1rem;
    color: var(--redcolor);
    font-weight: lighter;
    color:var(--greycolorshade);
    padding-left: 20px;
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon1 {
    font-size: 21px;
    color: var(--redcolor);
    color: var(--greycolorshade);
    height: 20px;
    padding-left: 40px;
    padding-top: 5px;
    transition: transform(2sec);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon2 {
    font-size: 21px;
    color: var(--redcolor);
    color: var(--greycolorshade);
    height: 20px;
    padding-left: 30px;
    padding-top: 5px;
    transition: transform(2sec);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon3 {
    font-size: 21px;
    color: var(--redcolor);
    color:var(--greycolorshade);
    height: 20px;
    padding-left: 30px;
    padding-top: 5px;
    transition: transform(2sec);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon4 {
    font-size: 21px;
    color: var(--redcolor);
    color: var(--greycolorshade);
    height: 20px;
    padding-left: 30px;
    padding-top: 5px;
    transition: transform(2sec);
}

.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon4:hover, .Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon3:hover, .Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon2:hover, .Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon1:hover {
    color: var(--redcolor);
}

.Dec6_ContactUs_4BS_alink {
    text-decoration: none;
}

.Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon1 {
/*    position: absolute; */
    font-size: 15px;
    color: var(--redcolor);
    padding-left: 10px;
    animation: Right 0.5s alternate ease infinite;
    text-decoration: none;
 /*   padding-left: 200px;
    margin-top: -100px;
    top: 896px;
    left: 550px;*/
}

.Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon1:hover {
    color: var(--greycolorshade);
}

.Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon3:hover {
    color: var(--redcolor);
}

@keyframes Right {
    from{
        transform: translateX(2px);
    }
    to{
        transform: translateX(-1px);
    }
}

.Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon2 {
 /*   position: absolute;  */
    font-size: 15px;
    color: var(--redcolor);
    margin-top: -30px;
    margin-left: 140px;
  /*  padding-left: 240px;
    margin-top: -80px;
    padding-bottom: 100px;  */
 /*   top: 896px;
    left: 555px;*/
 /*   top: 896px;
    left: 915px;  */
}

.Link1 {
    font-size: 1rem;
    color: var(--greycolorshade); 
    padding-top: 5px;
    font-weight: 400;
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link4 {
    text-decoration: none;
    padding-left: 20px;
    color: var(--greycolorshade); 
    transition: transform(2sec);
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link4:hover {
    color: var(--redcolor);
}
.Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon3 {
    color: var(--greycolorshade); 
    padding-left: 10px;
    font-size: 12px;
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_Div1 {
    width: 250px;
    height: 30px;
    color: var(--redcolor);
    margin-top: 240px;
}
.Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_Div2 {
    width: 250px;
    height: 30px;
    color: var(--redcolor);
    margin-top: 220px;
    background-color: aqua;
}

