@import "../MainCSS.css";

.Services_PCCB_BG {
    width: 100%;
    height: 580px;
    background-color: #f7f7f7;
    background: transparent; 
}

/*
.Dec6_Services_4PCB_MainBlock {
    display: grid;
    width: 80%;
    font-family: var(--fontfamily);
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    margin-top: -250px;
    grid-template-rows: 400px;
    grid-template-columns: 230px 230px 230px 230px;
    column-gap: 10px;
    text-align: center;
}
*/
.Dec6_Services_4PCB_Grid {
    margin-top: -100px;
}
.Dec6_Services_4PCB_MainBlock {
    font-family: var(--fontfamily);
    margin-top: -250px;
    text-align: center;
}

.Dec6_Services_4PCB_Cont {
    padding-top: 270px;
    padding-top: 30px;
}  
.Dec6_Services_4PCB_Cont_Paper {
    max-width: 230px;
    width:100%;
    height: 350px;
    margin: 0px auto;
}

.Dec6_Services_4PCB_Cont_Paper_Box {
    text-align: center;
    padding-top: 30px;
}

.Dec6_Services_4PCB_Cont_Paper_Box_Icon {
    font-size: 48px;
    color: #cc0000;
    padding-top: -30px;
}
.Dec6_Services_4PCB_Cont_Typ_H {
    font-size: 1rem;
    font-weight: 700;
    padding-top: 25px;
}
.Dec6_Services_4PCB_Cont_Typ_P {
    font-size: 0.85rem;
    font-weight: 500;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 15px;
}

