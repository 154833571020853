@import "../MainCSS.css";

.Dec6_ContactUs_WTCU_Block {
    width: 100%;
    height: 400px;
    font-family: var(--fontfamily);
    text-align: center;
    padding-top: 60px;
    margin-top: 110px;
    background-color:var(--whitecolor);
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.6)), url('../Images/CU3.jpg');
    /* background-image:  url('../Images/CU2.jpg'); */

    background-position: left ;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.Dec6_ContactUs_WTCU_Block_H1 {
    font-size: 3.2rem;
    margin-top: 95px ;
    color: var(--whitecolor);
}
.Dec6_ContactUs_WTCU_Block_H2 {
    padding-top: 20px;
    font-size: 1.95rem;
    font-weight: lighter;
    color: var(--whitecolor);
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    .Dec6_ContactUs_WTCU_Block {
        margin-top: 86.4px;
    }
}

@media (max-width: 1200px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 2.9rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.8rem;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 2.9rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.7rem;
    }
}

@media (max-width: 900px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 2.7rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.5rem;
    }
}
/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 2.4rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.3rem;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 700px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 2rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.3rem;
    }
}

@media (max-width: 540px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 1.7rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.2rem;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    .Dec6_ContactUs_WTCU_Block_H1 {
        font-size: 1.5rem;
    }
    .Dec6_ContactUs_WTCU_Block_H2 {
        font-size: 1.1rem;
    }
}

@media (max-width: 450px) {
}