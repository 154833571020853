@import "../MainCSS.css";

.Dec6_AboutUs_Purpose_Block {
    width: 100%;
    height: 400px;
    background: url("../Images/UKArch2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 67%;
    z-index: -1;
    margin-top: 110px;
    font-family: var(--fontfamily);
}
.Dec6_AboutUs_Purpose_Block_Cont {
 /*   position: absolute;
    top: 120px;
    left: 50px;  */
    display: inline-block;
    margin-top: 110px;
    margin-left: 30px;
    width: 400px;
    border-top: 5px solid #cc0000;
    height: 170px;
    background: linear-gradient(rgb(255,255,255));
    background-color: var(--whitecolor);
    opacity: 0.7;
    z-index: 2;
    padding-left: 20px;
}
.Dec6_AboutUs_Purpose_Block_Cont_MH {
    font-size: 1.55rem;
    color: var(--blackcolor);
    padding-top: 13px;
}
.Dec6_AboutUs_Purpose_Block_Cont_P {
    font-size: 1.1rem;
    color: var(--blackcolor);
    padding-top: 18px;
    padding-right: 10px;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    .Dec6_AboutUs_Purpose_Block {
        margin-top: 86.4px;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_AboutUs_Purpose_Block_Cont {
           width: 320px;
           height: 145px;
       }
       .Dec6_AboutUs_Purpose_Block_Cont_MH {
           font-size: 1.2rem;
           color: var(--blackcolor);
           padding-top: 13px;
       }
       .Dec6_AboutUs_Purpose_Block_Cont_P {
           font-size: 0.95rem;
       }
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Dec6_AboutUs_Purpose_Block_Cont {
        width: 270px;
        height: 160px;
        margin-top: 200px;
        padding-left:10px;
        margin-left: 10px;
    }
    .Dec6_AboutUs_Purpose_Block_Cont_MH {
        font-size: 1.1rem;
        color: var(--blackcolor);
        padding-top: 13px;
    }
    .Dec6_AboutUs_Purpose_Block_Cont_P {
        font-size: 0.92rem;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 500px) {
    .Dec6_AboutUs_Purpose_Block_Cont {
        width: 250px;
        height: 160px;
        margin-top: 200px;
        padding-left:5px;
        margin-left: 5px;
    }
    .Dec6_AboutUs_Purpose_Block_Cont_MH {
        font-size: 1rem;
        color: var(--blackcolor);
        padding-top: 13px;
    }
    .Dec6_AboutUs_Purpose_Block_Cont_P {
        font-size: 0.89rem;
    }
}

@media (max-width: 460px) {
    .Dec6_AboutUs_Purpose_Block_Cont {
        width: 220px;
        height: 160px;
        margin-top: 220px;
        padding-left:5px;
        margin-left: 5px;
    }
    .Dec6_AboutUs_Purpose_Block_Cont_MH {
        font-size: 1rem;
        color: var(--blackcolor);
        padding-top: 13px;
    }
    .Dec6_AboutUs_Purpose_Block_Cont_P {
        font-size: 0.84rem;
    }
}