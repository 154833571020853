@import "../MainCSS.css";

.Dec6_BOS_Items{
    color: var(--blackcolor);
    background-color:var(--whitecolor);
    /* background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)) ; */

    font-family: var(--fontfamily);
    width: 100%;
    /* height: 500px; */
    display: grid;
    grid-template-rows: 100px 100px 350px;
    grid-template-columns: 430px 1fr 1fr;
    gap: 20px;
}
.Dec6_BOS_Items_ImageDiv{
    grid-row: 1/-1;
    padding: 20px;
    justify-self: center;
}
.Dec6_BOS_Items_Image {
    width: 400px ;
    height: 500px;
}
.Dec6_BOS_Items_Heading {
    grid-column: 2/4;
}
.Dec6_BOS_Items_H{
    font-size: 2.5rem;
    color:var(--redcolor);
    font-weight: 500px;
    padding-top: 40px;
}
.Dec6_BOS_Items_Para{
    grid-column: 2/4;
}
.Dec6_BOS_Items_Para_P{
    font-size: 1.2rem;
    line-height: 30px;
    color:#000;
    padding-top: 15px;
}
.fa-handshake-o{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 10px;
}
.fa-laptop{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 10px;
}
.fa-link{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 10px;
}
.fa-code{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 10px;
}
.Dec6_BOS_Items_List1{
    font-size: 1.1rem;
    line-height: 50px;
    padding-top: 10px;
}
.Dec6_BOS_Items_List1 ul li{
    list-style: none;
    /* color: var(--blackcolor); */
    color: #000;
}
.fa-digital-tachograph{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 10px;
}
.fa-intercom{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 15px;
}
.fa-user-secret{
    color:var(--redcolor);
    font-size: 20px;
    padding-right: 15px;
}
.fa-database{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 15px;
}
.Dec6_BOS_Items_List2{
    grid-column: 3/4;
    font-size: 1.1rem;
    line-height: 50px;
    padding-top: 10px;
}
.Dec6_BOS_Items_List2 ul li{
    list-style: none;
    /* color: var(--blackcolor); */
    color: #000;
}
/*
@media(max-width: 768px) {
    .Dec6_BOS_Items{
        width: 96%;
        display: block;
        margin-left: 15px;
        height: 900px;
        width: 48%;
        width: 95%;
    }
    .Dec6_BOS_Items_ImageDiv{
        margin-left: 13.3px;
    }
    .Dec6_BOS_Items_Image {
        width: 399px;
        height: 400px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 20px;
        font-size: 2.2rem;
    }
    .Dec6_BOS_Items_Para_P{ 
        font-size: 1.16rem;
        line-height: 28px;
    }
    .Dec6_BOS_Items_List1 {
        padding-left: 40px;
    }
}*/

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 750px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
    }
    .Dec6_BOS_Items_Image {
        width: 100% ;
        height: 450px;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/2;
        height: 40vh;
        width: 40vw;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 2/3;
        height: 40vh;
        width: 43vw;
    }
    .Dec6_BOS_Items_H{
        font-size: 2rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 1.1rem;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_BOS_Items{
        height: 800px;
    }
    .Dec6_BOS_Items_H{
        font-size: 2rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 1.1rem;
    }
    
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 750px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
    }
    .Dec6_BOS_Items_Image {
        width: 97vw ;
        height: 70vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/2;
        height: 40vh;
        width: 42vw;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 2/3;
        height: 40vh;
        width: 43vw;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.9rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 1.05rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.95rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.95rem;
    }
}

@media (max-width: 550px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 750px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_Image {
        width: 94.2vw ;
        height: 60vh;
    }
    
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 480px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 760px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_Image {
        width: 92.5vw ;
        height: 70vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/2;
        height: 42vh;
        width: 42vw;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 2/3;
        height: 42vh;
        width: 43vw;
        margin-left: 15px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.4rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 0.9rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.8rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.8rem;
    }
    .fa-handshake-o{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 10px;
    }
    .fa-laptop{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 10px;
    }
    .fa-link{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 10px;
    }
    .fa-code{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 10px;
    }
    .fa-digital-tachograph{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 10px;
    }
    .fa-intercom{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 15px;
    }
    .fa-user-secret{
        color:var(--redcolor);
        font-size: 15px;
        padding-right: 15px;
    }
    .fa-database{
        color: var(--redcolor);
        font-size: 15px;
        padding-right: 15px;
    }
}


/* 
@media (max-width: 420px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 800px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
        justify-self: center;
    }
    .Dec6_BOS_Items_Image {
        width: 92.5vw ;
        height: 70vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 10px;
        margin-top: 40px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 10px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/2;
        height: 42vh;
        width: 42vw;
        margin-left: 10px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 2/3;
        height: 42vh;
        width: 43vw;
        margin-left: 8px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.2rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 0.8rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.74rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.74rem;
    }
} */

/*
@media (max-width: 420px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 760px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_Image {
        width: 92.5vw ;
        height: 70vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/2;
        height: 42vh;
        width: 42vw;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 2/3;
        height: 42vh;
        width: 43vw;
        margin-left: 2px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.1rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 0.76rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.7rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.7rem;
    }
} */

@media only screen and (max-width: 420px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 800px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
        margin-left: 0px;
        padding-bottom: 15px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
        justify-self: center;
    }
    .Dec6_BOS_Items_Image {
        width: 90vw ;
        height: 55vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 5px;
        padding-left: 15px;
        margin-top: 40px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 5px;
        padding-left: 15px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/2;
        height: 42vh;
        width: 42vw;
        margin-left: 5px;
        padding-left: 15px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 2/3;
        height: 42vh;
        width: 43vw;
        margin-left: 5px;
        padding-left: 15px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.1rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 0.76rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.7rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.7rem;
    }
} 

@media (max-width: 350px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 950px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr ;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
        margin-bottom: 0px;
    }
    .Dec6_BOS_Items_Image {
        width: 92.5vw ;
        height: 80vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/-1;
        height: 35vh;
        width: 80vw;
        margin-left: 20px;
        padding-top: 0px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 1/-1;
        height: 42vh;
        width: 80vw;
        margin-left: 20px;
        padding-top: 0px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.1rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 0.85rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.9rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.9rem;
    }
}

@media (max-width: 320px) {
    .Dec6_BOS_Items{
        display: grid;
        height: 950px;
        grid-template-rows: 370px 20px 20px 70px;
        grid-template-columns: 1fr ;
        row-gap: 0px;
        margin-left: 0px;
    }
    .Dec6_BOS_Items_ImageDiv{
        grid-column: 1/-1;
        margin-left: 5px;
        margin-bottom: 0px;
    }
    .Dec6_BOS_Items_Image {
        width: 92.5vw ;
        height: 70vh;
    }
    .Dec6_BOS_Items_Heading {
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_H{
        padding-top: 0px;
    }
    .Dec6_BOS_Items_Para{
        grid-column: 1/-1;
        margin-left: 5px;
    }
    .Dec6_BOS_Items_List1 {
        grid-column: 1/-1;
        height: 30vh;
        width: 80vw;
        margin-left: 20px;
        padding-top: 0px;
    }
    .Dec6_BOS_Items_List2 {
        grid-column: 1/-1;
        height: 42vh;
        width: 80vw;
        margin-left: 20px;
        padding-top: 0px;
    }
    .Dec6_BOS_Items_H{
        font-size: 1.1rem;
    }
    .Dec6_BOS_Items_Para_P{
        font-size: 0.85rem;
    }
    .Dec6_BOS_Items_List1{
        font-size: 0.9rem;
    }
    .Dec6_BOS_Items_List2{
        font-size: 0.9rem;
    }
}