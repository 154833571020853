@import "../MainCSS.css";

* {
  margin: 0;
  padding: 0;
  font-family: var(--fontfamily);
}

.D6_Services_Navbar_Items {
 /* position: fixed;*/
  width: 100%;
  background-color: var(--whitecolor);
  background: transparent;
  /*padding-right: 88px;*/
  height: 140px; 
  /* padding-left: 88px;*/
}
/*
.D6_Services_Navbar_Container::before { 
  content: '';
  background-image: url("../Images/Industries.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
  z-index: -2;
}*/

.D6_Services_Navbar_Items_BGColor .scrolled {
    background-color: white;
}

.D6_Services_Navbar_Items_Image {
  float: left;
  height: 85px;
  width: 230px;
  margin: 20px 80px;
}

.D6_Services_Navbar_Items_List {
  overflow: auto;
  margin-right: 80px;
}

.D6_Services_Navbar_Items li {
  float: right;
  list-style: none;
  margin: 55px 20px;
}

.D6_Services_Navbar_Items li a {
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--blackcolor);
  -webkit-transition: color 0.3sec;
  transition: color 0.3sec;
}

.D6_Services_Navbar_Items li a:hover {
  color: var(--redcolor);
}