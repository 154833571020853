.Dec6_Industries_BgImage_Block {
    width: 100%;
    height: 500px;
    margin-top: 110px;
    background-image: url('../Images/Industries2.jpg');
    background-image: url('../Images/Indus.jpg');
 /*   background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('../Images/Indus.jpg');   */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: right;
    background-position: bottom;
}


/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    .Dec6_Industries_BgImage_Block {
        margin-top: 86.4px;
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 450px) {
}