@import "../MainCSS.css";

.Dec6_Services_NBBPC_Block {
    width: 100%;
    height: 200px;
    font-family: var(--fontfamily);
    background-color: var(--whitecolor);
    text-align: center;
    margin-top: 50px;
}
.Dec6_Services_NBBPC_Block_H1 {
    color: var(--redcolor);
    font-size: 1.7rem;
}
.Dec6_Services_NBBPC_Block_H2 {
    padding-top: 20px;
    color: var(--redcolor);
    font-weight: lighter;
}
.Dec6_Services_NBBPC_Block_P {
    font-size: 0.95rem;
    line-height: 25px;
    padding-top: 30px;
    padding-left: 200px;
    padding-right: 200px;
}
.Dec6_Services_NBBPC_Block_Icon {
    color: var(--redcolor);
    padding-top: 50px;
    font-size: 30px;
    font-weight: 900;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_Services_NBBPC_Block_H1 {
        font-size: 1.55rem;
    }
    .Dec6_Services_NBBPC_Block_H2 {
        padding-top: 20px;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.95rem;
        line-height: 25px;
        padding-top: 30px;
        padding-left: 130px;
        padding-right: 130px;
    }
    .Dec6_Services_NBBPC_Block_Icon {
        color: var(--redcolor);
        padding-top: 50px;
        font-size: 30px;
        font-weight: 900;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 840px) {
    .Dec6_Services_NBBPC_Block_H1 {
        font-size: 1.44rem;
    }
    .Dec6_Services_NBBPC_Block_H2 {
        padding-top: 20px;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.95rem;
        line-height: 25px;
        padding-top: 30px;
        padding-left: 70px;
        padding-right: 70px;
    }
    .Dec6_Services_NBBPC_Block_Icon {
        color: var(--redcolor);
        padding-top: 50px;
        font-size: 30px;
        font-weight: 900;
    }
}

@media (max-width: 720px) {
    .Dec6_Services_NBBPC_Block_H1 {
        font-size: 1.33rem;
    }
    .Dec6_Services_NBBPC_Block_H2 {
        padding-top: 20px;
        font-size: 1.05rem;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.93rem;
        line-height: 25px;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .Dec6_Services_NBBPC_Block_Icon {
        color: var(--redcolor);
        padding-top: 50px;
        font-size: 30px;
        font-weight: 900;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 620px) {
    .Dec6_Services_NBBPC_Block_H1 {
        font-size: 1.2rem;
    }
    .Dec6_Services_NBBPC_Block_H2 {
        padding-top: 20px;
        font-size: 1.05rem;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.93rem;
        line-height: 25px;
        padding-top: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .Dec6_Services_NBBPC_Block_Icon {
        color: var(--redcolor);
        padding-top: 30px;
        font-size: 30px;
        font-weight: 900;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    .Dec6_Services_NBBPC_Block {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .Dec6_Services_NBBPC_Block_H1 {
        font-size: 1.1rem;
    }
    .Dec6_Services_NBBPC_Block_H2 {
        padding-top: 10px;
        font-size: 1rem;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.93rem;
        line-height: 25px;
        padding-top: 30px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .Dec6_Services_NBBPC_Block_Icon {
        color: var(--redcolor);
        padding-top: 20px;
        font-size: 20px;
        font-weight: 900;
    }
}

@media (max-width: 400px) {
    .Dec6_Services_NBBPC_Block {
        margin-top: 30px;
        margin-bottom: 80px;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.87rem;
        line-height: 25px;
        padding-top: 30px;
        padding-left: 2px;
        padding-right: 2px;
    }
}

@media (max-width: 350px) {
    .Dec6_Services_NBBPC_Block {
        margin-top: 30px;
        margin-bottom: 80px;
    }
    .Dec6_Services_NBBPC_Block_P {
        font-size: 0.8rem;
        line-height: 20px;
        padding-top: 30px;
        padding-left: 2px;
        padding-right: 2px;
    }
}