@import "../MainCSS.css";


.typo{
    font-weight: 500 !important;
    font-size: 12px !important;
    margin-bottom: 10px !important;
    border-bottom: black !important;
    border-bottom-width: 8px !important;
    cursor: pointer;
}
.box_dash{
    width: 400px;
}

@media (max-width: 990px) {
    .box_dash{
        width: 250px;
    }
}