@import "../MainCSS.css";

.Dec6_Footer_Items_Block {
    background-color: rgb(0,0,0);
}
.Dec6_Footer_Items {
    height: 80px;
    color:var(--whitecolor);
    font-family: var(--fontfamily);
 /*   display: grid;
    grid-template-rows: 50px 15px;
    grid-template-columns: 70px 140px 180px 300px 300px;
    gap: 0px;*/
    text-align: center;
    align-items: center;
}
.Dec6_Footer_Items_NavLink {
  /*  margin-top: 10px;  */
    font-size: 0.85rem;
    text-align: center;
    height: 15px;
    margin-top: 10px;
}
.Dec6_Footer_Items_NavLink1 {
    display: inline-block;
    width: 70px;
/*    grid-column: 1/2;*/
    border-right: 1px solid var(--whitecolor);
}
.Dec6_Footer_Items_NavLink2 {
    display: inline-block;
    width: 140px;
 /*   grid-column: 2/3;  */
    border-right: 1px solid var(--whitecolor);
}
.Dec6_Footer_Items_NavLink3 {
    display: inline-block;
    width: 180px;
 /*   grid-column: 3/4;  */
}
.Dec6_Footer_Items a {
    text-decoration: none;
    font-size: 0.86rem;
    color: var(--whitecolor);
}

.Dec6_Footer_Items_P_Div {
/*    grid-column: 4/6;
    grid-row: 2/3; */
    margin-top: 30px;
}

.Dec6_Footer_Items_P {
    text-align: center;
    font-size: 0.95rem;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
  /*  .Dec6_Footer_Items {
        padding-top: 15px;
    }*/
 /*   .Dec6_Footer_Items {
        height: 80px;
        width: 100%;
        color:var(--whitecolor);
        font-family: var(--fontfamily);
        display: grid;
        grid-template-rows: 50px 15px;
        grid-template-columns: 50px 150px 200px;
        gap: 0px;
        text-align: center;
        align-items: center;
    }
    .Dec6_Footer_Items_NavLink1 {
        grid-column: 1/2;
        text-align: center;
        border-right: 1px solid var(--whitecolor);
    }
    .Dec6_Footer_Items_NavLink2 {
        grid-column: 2/3;
        text-align: center;
        border-right: 1px solid var(--whitecolor);
    }
    .Dec6_Footer_Items_NavLink3 {
        grid-column: 3/4;
        text-align: center;
    }
    .Dec6_Footer_Items_P_Div {
        grid-column: 1/-1;
        grid-row: 2/3; 
    }*/
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
 /*   .Dec6_Footer_Items {
        height: 80px;
        width: 30%;
        color:var(--whitecolor);
        font-family: var(--fontfamily);
        display: grid;
        grid-template-rows: 50px 15px;
        grid-template-columns: 50px 110px 140px 150px 150px;
        grid-template-columns: 7vw 15vw 18vw 20vw 20vw;
        gap: 0px;
        text-align: center;
        align-items: center;
    }
    .Dec6_Footer_Items a {
        font-size: 0.7rem;
    }
    .Dec6_Footer_Items_P {
        font-size: 0.8rem;
    }
    .Dec6_Footer_Items_P_Div {
        grid-column: 4/6;
        grid-row: 2/3; 
    }
    .Dec6_Footer_Items_P {
        text-align: left;
    }*/
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 420px) {
    .Dec6_Footer_Items_NavLink1 {
        display: inline-block;
        width: 45px;
    }
    .Dec6_Footer_Items_NavLink2 {
        display: inline-block;
        width: 95px;
     /*   grid-column: 2/3;  */
        border-right: 1px solid var(--whitecolor);
    }
    .Dec6_Footer_Items_NavLink3 {
        display: inline-block;
        width: 125px;
     /*   grid-column: 3/4;  */
    }
    .Dec6_Footer_Items a {
        text-decoration: none;
        font-size: 0.68rem;
        color: var(--whitecolor);
    }
    .Dec6_Footer_Items_P {
        text-align: center;
        font-size: 0.77rem;
    }
}