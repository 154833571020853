@import "../MainCSS.css";

.Dec6_JoinUs_ECS_New1_Block {
    display: grid;
    width: 80%;
    margin: 30px auto;
    justify-content: space-evenly;
    grid-template-rows: 100px 50px;
    grid-template-columns: repeat(3,1fr);
    font-family: var(--fontfamily);
    column-gap: 40px;
}

.Dec6_JoinUs_ECS_New1_Block_Button{
 /*   border-radius: 14px;  */
    background-color: var(--redcolor);
    font-size: 1.7rem;
    font-weight: 600;
    border: none;
    outline: none;
    width: 320px;
    height: 80px;
    transition: transform(2s);    
}

.Dec6_JoinUs_ECS_New1_Block_Button:hover {
    background-color: var(--whitecolor);
    color: var(--redcolor);
    font-weight: bold;
    border: 2px solid var(--redcolor);
}  
.Dec6_JoinUs_ECS_New1_Block_Button1 {
    grid-column: 1/2;
    cursor: pointer;
}
.Dec6_JoinUs_ECS_New1_Block_Button2 {
    grid-column: 2/3;
    cursor: pointer;
}
.Dec6_JoinUs_ECS_New1_Block_Button3 {
    grid-column: 3/4;
    cursor: pointer;
}
.Dec6_JoinUs_ECS_New1_Block_Button4 {
    grid-column: 4/5;
    cursor: pointer;
}

.D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
    padding-top: 10px;
    padding-left: 5px;
}
.D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
    padding-top: 3px;
    padding-left: 5px;
}
.D6_JoinUs_ECS_New1_Block_Button_DropDown a{
    text-decoration: none;
    color: var(--redcolor);
    font-size: 1.3rem;
    font-weight: 600;
}
.D6_JoinUs_ECS_New1_Block_Button_DropDown a:hover {
    color: black;
}
.D6_JoinUs_ECS_New1_Block_Button_DropDown {
    display: none;
    position: absolute;
    width: 300px;
    z-index: 1;
}

.Dec6_JoinUs_ECS_New1_Button2_InlBlock {
    position: relative;
    display: inline-block;
}

.Dec6_JoinUs_ECS_New1_Button2_InlBlock:hover .D6_JoinUs_ECS_New1_Block_Button2_DropDown {
    display: block;
}

.Dec6_JoinUs_ECS_New1_Button3_InlBlock {
    position: relative;
    display: inline-block;
}

.Dec6_JoinUs_ECS_New1_Button3_InlBlock:hover .D6_JoinUs_ECS_New1_Block_Button3_DropDown {
    display: block;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

@media(max-width: 1070px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 10px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
     /*   border-radius: 14px;  */
        background-color: var(--redcolor);
        font-size: 1.7rem;
        width: 290px;
        height: 80px; 
    }
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 920px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 10px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 1.3rem;
        width: 230px;
        height: 80px; 
        font-weight: 500;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 1.1rem;
        font-weight: 600;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 750px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 10px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 1.1rem;
        width: 190px;
        height: 80px; 
        font-weight: 500;
    }
    .Dec6_JoinUs_ECS_New1_Block_Button:hover {
        font-weight: 600;
    }  
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 0.95rem;
        font-weight: 600;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown {
        display: none;
        position: absolute;
        width: 250px;
        z-index: 1;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
        padding-top: 10px;
        padding-left: 2px;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
        padding-top: 3px;
        padding-left: 2px;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 620px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 5px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 1.1rem;
        width: 170px;
        height: 80px; 
        font-weight: 500;
    }
    .Dec6_JoinUs_ECS_New1_Block_Button:hover {
        font-weight: 600;
    }  
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 0.9rem;
        font-weight: 600;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown {
        display: none;
        position: absolute;
        width: 200px;
        z-index: 1;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
        padding-top: 10px;
        padding-left: 0px;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
        padding-top: 3px;
        padding-left: 0px;
    }
}

@media (max-width: 560px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 3px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 1.1rem;
        width: 150px;
        height: 80px; 
        font-weight: 500;
    }
    .Dec6_JoinUs_ECS_New1_Block_Button:hover {
        font-weight: 600;
    }  
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 0.8rem;
        font-weight: 600;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown {
        display: none;
        position: absolute;
        width: 200px;
        z-index: 1;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
        padding-top: 10px;
        padding-left: 0px;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
        padding-top: 3px;
        padding-left: 0px;
    }
}

@media (max-width: 500px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 3px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 1.1rem;
        width: 120px;
        height: 80px; 
        font-weight: 500;
    }
    .Dec6_JoinUs_ECS_New1_Block_Button:hover {
        font-weight: 600;
    }  
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 0.8rem;
        font-weight: 600;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown {
        display: none;
        position: absolute;
        width: 150px;
        z-index: 1;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
        padding-top: 10px;
        padding-left: 0px;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
        padding-top: 3px;
        padding-left: 0px;
    }
}

@media (max-width: 400px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 1px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 1rem;
        width: 110px;
        height: 70px; 
        font-weight: 500;
    }
    .Dec6_JoinUs_ECS_New1_Block_Button:hover {
        font-weight: 600;
    }  
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 0.74rem;
        font-weight: 600;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown {
        display: none;
        position: absolute;
        width: 120px;
        z-index: 1;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
        padding-top: 10px;
        padding-left: 0px;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
        padding-top: 3px;
        padding-left: 0px;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 360px) {
    .Dec6_JoinUs_ECS_New1_Block {
        display: grid;
        width: 80%;
        margin: 30px auto;
        justify-content: space-evenly;
        grid-template-rows: 100px 50px;
        grid-template-columns: repeat(3,1fr);
        font-family: var(--fontfamily);
        column-gap: 1px;
    }
    
    .Dec6_JoinUs_ECS_New1_Block_Button{
        font-size: 0.9rem;
        width: 90px;
        height: 70px; 
        font-weight: 500;
    }
    .Dec6_JoinUs_ECS_New1_Block_Button:hover {
        font-weight: 600;
    }  
    .D6_JoinUs_ECS_New1_Block_Button_DropDown a{
        font-size: 0.66rem;
        font-weight: 500;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown {
        display: none;
        position: absolute;
        width: 120px;
        z-index: 1;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown_H {
        padding-top: 10px;
        padding-left: 0px;
    }
    .D6_JoinUs_ECS_New1_Block_Button_DropDown2_H {
        padding-top: 3px;
        padding-left: 0px;
    }
}

@media (max-width: 450px) {
}