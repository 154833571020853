@import "../MainCSS.css";

.Dec6_AboveFooter_Container{
    /* background-color: rgb(53, 61, 66); */
    width: 100%;
    height: auto;
    height: 360px;
    display: flex;
    color:var(--whitecolor);
    font-family: var(--fontfamily);
    justify-content: center;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: lighter;
    background-image: linear-gradient(rgba(0, 0,0,0.8),rgb(0,0,0,0.9));
    background-repeat: no-repeat;
    background-size: cover;
}

.Dec6_AboveFooter_Item {
    padding-top: 30px;
}

#Dec6_AboveFooter_Item-1 {
    width: 500px;
    height: 380px;
}
#Dec6_AboveFooter_Item-2 {
    width: 400px;
    height: 380px;
}
#Dec6_AboveFooter_Item-3 {
    width: 300px;
    height: 380px;
}

.aservices{
    color: var(--whitecolor);
    text-decoration: none;
}

.Dec6_AboveFooter_P{
    padding-right: 70px;
    padding-left: 30px;
    padding-bottom: 10px;
    line-height: 26px;
    font-size: 1.1rem;
}
.Dec6_AboveFooter_H1{
    padding-left: 30px;
    color:var(--redcolor);
    font-weight: lighter;
    padding-bottom: 35px;
    font-size: 1.75rem;
}
.Dec6_AboveFooter_Item ul{
    list-style: none;
}
.Dec6_AboveFooter_Item li a{
    padding-right: 6px;
    text-decoration: none;
    color: var(--whitecolor);
    font-family: var(--fontfamily);
    font-size: 1.1rem;
    padding-bottom: 10px;
    line-height: 48px;
}
.Dec6_AboveFooter_H2{
    padding-left: 160px;
    color: var(--redcolor);
    font-weight: lighter;
    padding-bottom: 20px;
    font-size: 1.6rem;
}
.Dec6_AboveFooter_List2 li a {
    padding-left: 160px;
}
.Dec6_AboveFooter_H3{
    padding-left: 120px;
    color:var(--redcolor);
    font-weight: lighter;
    padding-bottom: 20px;
    font-size: 1.6rem;
}
.Dec6_AboveFooter_List3{
    padding-left: 120px;
}

/*  --- Fa Symbols ---  */
.fa-envelope{
    color: var(--redcolor);
    font-size: 20px;
    padding-right: 15px;
}
.fa-phone-alt{
    color:var(--redcolor);
    font-size: 25px;
    padding-right: 15px;
}
.fa-whatsapp{
    color:var(--redcolor);
    font-size: 25px;
    padding-right: 15px;
}

.circle-icon {
  /*  background: rgb(53, 61, 66);   */
    background: transparent;
    border-radius: 50%;
    text-align: center; 
    line-height: 10px;
    padding: 15px 11px;
    vertical-align: middle;
    border: 2.6px solid var(--whitecolor);
    transition: .3sec;
}
.circle-icon:hover {
    transform: scale(1.2);
    transition: .5s;
    background-color: var(--redcolor);
    border-color: var(--redcolor);
}
.centericons{
    text-align: center;
}
.fa-facebook-f{
    color: var(--whitecolor);
    font-size: 20px;
    justify-content: center;
    text-align: center;
}
.circle-icon-f {
      padding: 15px 15px;
      margin-left: -10px;
  }
.fa-twitter{
    color:var(--whitecolor);
    font-size: 20px;
}
.circle-icon-tw {
      padding: 15px 11px;
  }
.fa-linkedin-in{
    color: var(--whitecolor);
    font-size: 20px;
}
.circle-icon-in {
      padding: 15px 12px;
  }
.fa-instagram{
    color: var(--whitecolor);
    font-size: 25px;
}
.Dec6_AboveFooter_SymbolsList1{
    text-decoration: none;
    margin-left: 50px;
    margin-right: 60px;
    position: absolute;
    margin-top: 20px;
}

.Dec6_AboveFooter_SymbolsList1 a{
    text-decoration: none;
    margin-right: 20px;
}
/*
@media(max-width: 768px) {
    .Dec6_AboveFooter_Container{ 
        display: grid;
        grid-template-rows: repeat(2,200);
        grid-template-columns: 1fr 1fr;
    }
    #Dec6_AboveFooter_Item-1 {
        grid-column: 1/-1;
        height: 200px;
    }
    #Dec6_AboveFooter_Item-2 {
        grid-column: 1/2;
        padding-top: 2px;
        height: 130px;
    }
    #Dec6_AboveFooter_Item-3 {
        grid-column: 2/3;
        padding-top: 2px;
        height: 130px;
    }
    .Dec6_AboveFooter_H2{
        padding-left: 60px;
    }
    .Dec6_AboveFooter_List2 li a {
        padding-left: 60px;
    }
    .Dec6_AboveFooter_H3{
        padding-left: 20px;
    }
    .Dec6_AboveFooter_List3{
        padding-left: 20px;
    }
}*/

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 1200px) {

    #Dec6_AboveFooter_Item-1 {
        width: 450px;
    }
    #Dec6_AboveFooter_Item-2 {
        width: 250px;
    }
    #Dec6_AboveFooter_Item-3 {
        width: 200px;
    }

    .Dec6_AboveFooter_P{
        padding-left: 0px;
    }
    .Dec6_AboveFooter_H1{
        padding-left: 0px;
    }
    .Dec6_AboveFooter_H2{
        padding-left: 20px;
    }
    .Dec6_AboveFooter_List2 li a {
        padding-left: 20px;
    }
    .Dec6_AboveFooter_H3{
        padding-left: 50px;
    }
    .Dec6_AboveFooter_List3{
        padding-left: 50px;
    }
    .Dec6_AboveFooter_SymbolsList1{
        margin-left: 10px;
    }
}

@media (max-width: 920px) {
    .Dec6_AboveFooter_Container{
        height: 400px;
    }
    #Dec6_AboveFooter_Item-1 {
        width: 300px;
    }
    #Dec6_AboveFooter_Item-2 {
        width: 250px;
    }
    #Dec6_AboveFooter_Item-3 {
        width: 200px;
    }

    .Dec6_AboveFooter_P{
        padding-left: -20px;
        padding-right: 30px;
    }
    .Dec6_AboveFooter_H1{
        padding-left: -20px;
    }
    .Dec6_AboveFooter_H2{
        padding-left: 20px;
    }
    .Dec6_AboveFooter_List2 li a {
        padding-left: 20px;
    }
    .Dec6_AboveFooter_H3{
        padding-left: 50px;
    }
    .Dec6_AboveFooter_List3{
        padding-left: 50px;
    }
    .Dec6_AboveFooter_SymbolsList1{
        margin-left: 10px;
        margin-top: 10px;
    }

    /*  --- Fa Symbols ---  */
    .fa-envelope{
        font-size: 1rem;
    }
    .fa-phone-alt{
        font-size: 1.1rem;
    }
    .fa-whatsapp{
        font-size: 1.1rem;
    }
    .circle-icon {
        /*  background: rgb(53, 61, 66);   */
          background: transparent;
          border-radius: 50%;
          text-align: center; 
          line-height: 10px;
          padding: 10px 7px;
          vertical-align: middle;
          border: 2.6px solid var(--whitecolor);
          transition: .3sec;
      }
      .centericons{
          text-align: center;
      }
      .fa-facebook-f{
          color: var(--whitecolor);
          font-size: 18px;
          justify-content: center;
          text-align: center;
      }
      .circle-icon-f {
            padding: 10px 10px;
            margin-left: -10px;
        }
      .fa-twitter{
          color:var(--whitecolor);
          font-size: 17px;
      }
      .circle-icon-tw {
            padding: 10px 6px;
        }
      .fa-linkedin-in{
          color: var(--whitecolor);
          font-size: 17px;
      }
      .circle-icon-in {
            padding: 10px 7px;
        }
      .fa-instagram{
          color: var(--whitecolor);
          font-size: 20px;
      }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    #Dec6_AboveFooter_Item-1 {
        width: 300px;
    }
    #Dec6_AboveFooter_Item-2 {
        width: 250px;
    }
    #Dec6_AboveFooter_Item-3 {
        width: 200px;
    }

    .Dec6_AboveFooter_P{
        padding-left: 0px;
        padding-right: 30px;
    }
    .Dec6_AboveFooter_H1{
        padding-left: 0px;
    }
    .Dec6_AboveFooter_H2{
        padding-left: 20px;
    }
    .Dec6_AboveFooter_List2 li a {
        padding-left: 20px;
    }
    .Dec6_AboveFooter_H3{
        padding-left: 50px;
    }
    .Dec6_AboveFooter_List3{
        padding-left: 50px;
    }
    .Dec6_AboveFooter_SymbolsList1{
        margin-left: 10px;
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .Dec6_AboveFooter_Container{
        height: auto;
    }
    .Dec6_AboveFooter_Item {
        padding-top: 30px;
        margin-right: 30px;
        margin-left: 40px;
    }
    .Dec6_AboveFooter_Container{ 
        display: grid;
        grid-template-rows: repeat(2,200);
        grid-template-columns: 1fr 1fr;
    }
    #Dec6_AboveFooter_Item-1 {
        width: 90vw;
        height: 45vh;
        grid-column: 1/-1;
    }
    .Dec6_AboveFooter_P{
        padding-left: 0px;
        padding-right: 0px;
    }
    #Dec6_AboveFooter_Item-2 {
        width: 50vw;
        height: 50vh;
        grid-column: 1/2;
        padding-top: 10px;
        text-align: left;
    }
    #Dec6_AboveFooter_Item-3 {
        width: 30vw;
        height: 50vh;
        grid-column: 2/3;
        padding-top: 10px;
    }
    .Dec6_AboveFooter_H2{
        padding-left: 0px;
    }
    .Dec6_AboveFooter_List2 li a {
        padding-left: 0px;
    }
    .Dec6_AboveFooter_H3{
        padding-left: 0px;
    }
    .Dec6_AboveFooter_List3{
        padding-left: 0px;
    }
    .Dec6_AboveFooter_SymbolsList1{
        margin-top: 20px;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Dec6_AboveFooter_P{
        font-size: 0.95rem;
    }
    .Dec6_AboveFooter_H1{
        font-size: 1.3rem;
    }
    .Dec6_AboveFooter_Item li a{
        font-size: 0.95rem;
    }
    .Dec6_AboveFooter_H2{
        font-size: 1.3rem;
    }
    .Dec6_AboveFooter_H3{
        font-size: 1.3rem;
    }
    
    /*  --- Fa Symbols ---  */
    .fa-envelope{
        font-size: 1rem;
    }
    .fa-phone-alt{
        font-size: 1.1rem;
    }
    .fa-whatsapp{
        font-size: 1.1rem;
    }
    .circle-icon {
        /*  background: rgb(53, 61, 66);   */
          background: transparent;
          border-radius: 50%;
          text-align: center; 
          line-height: 10px;
          padding: 10px 7px;
          vertical-align: middle;
          border: 2.6px solid var(--whitecolor);
          transition: .3sec;
      }
      .centericons{
          text-align: center;
      }
      .fa-facebook-f{
          color: var(--whitecolor);
          font-size: 18px;
          justify-content: center;
          text-align: center;
      }
      .circle-icon-f {
            padding: 10px 10px;
            margin-left: -10px;
        }
      .fa-twitter{
          color:var(--whitecolor);
          font-size: 17px;
      }
      .circle-icon-tw {
            padding: 10px 6px;
        }
      .fa-linkedin-in{
          color: var(--whitecolor);
          font-size: 17px;
      }
      .circle-icon-in {
            padding: 10px 7px;
        }
      .fa-instagram{
          color: var(--whitecolor);
          font-size: 20px;
      }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    .Dec6_AboveFooter_SymbolsList1{
        margin-top: 10px;
    }
}

@media (max-width: 420px) {
    .Dec6_AboveFooter_Item {
        padding-top: 30px;
        margin-right: 0px;
        margin-left: 10px;
    }
    .Dec6_AboveFooter_Container{ 
        display: grid;
        grid-template-rows: repeat(2,200);
        grid-template-columns: 1fr 1fr;
    }
    #Dec6_AboveFooter_Item-1 {
        width: 90vw;
        height: 45vh;
        grid-column: 1/-1;
    }
    #Dec6_AboveFooter_Item-2 {
        width: 50vw;
        height: 50vh;
        grid-column: 1/2;
        padding-top: 10px;
        text-align: left;
    }
    #Dec6_AboveFooter_Item-3 {
        width: 35vw;
        height: 50vh;
        grid-column: 2/3;
        padding-top: 10px;
    }

    .Dec6_AboveFooter_P{
        font-size: 0.9rem;
    }
    .Dec6_AboveFooter_H1{
        font-size: 1.15rem;
    }
    .Dec6_AboveFooter_Item li a{
        font-size: 0.9rem;
    }
    .Dec6_AboveFooter_H2{
        font-size: 1.15rem;
    }
    .Dec6_AboveFooter_H3{
        font-size: 1.15rem;
    }
    
    .circle-icon {
        /*  background: rgb(53, 61, 66);   */
          background: transparent;
          border-radius: 50%;
          text-align: center; 
          line-height: 10px;
          padding: 10px 7px;
          vertical-align: middle;
          border: 2.6px solid var(--whitecolor);
          transition: .3sec;
      }
      .centericons{
          text-align: center;
      }
      .fa-facebook-f{
          color: var(--whitecolor);
          font-size: 18px;
          justify-content: center;
          text-align: center;
      }
      .circle-icon-f {
            padding: 10px 10px;
            margin-left: -10px;
        }
      .fa-twitter{
          color:var(--whitecolor);
          font-size: 17px;
      }
      .circle-icon-tw {
            padding: 10px 6px;
        }
      .fa-linkedin-in{
          color: var(--whitecolor);
          font-size: 17px;
      }
      .circle-icon-in {
            padding: 10px 7px;
        }
      .fa-instagram{
          color: var(--whitecolor);
          font-size: 20px;
      }
      .Dec6_AboveFooter_SymbolsList1{
        margin-top: 5px;
        margin-left:10px;
    }
}

@media (max-width: 450px) {
    .Dec6_AboveFooter_Item {
        padding-top: 30px;
        margin-right: 0px;
        margin-left: 5px;
    }
    .Dec6_AboveFooter_Container{ 
        display: grid;
        grid-template-rows: repeat(2,200);
        grid-template-columns: 1fr 1fr;
    }
    #Dec6_AboveFooter_Item-1 {
        width: 90vw;
        height: 47vh;
        grid-column: 1/-1;
    }
    #Dec6_AboveFooter_Item-2 {
        width: 53vw;
        height: 50vh;
        grid-column: 1/2;
        padding-top: 10px;
        text-align: left;
    }
    #Dec6_AboveFooter_Item-3 {
        width: 35vw;
        height: 50vh;
        grid-column: 2/3;
        padding-top: 10px;
    }

    .Dec6_AboveFooter_P{
        font-size: 0.9rem;
    }
    .Dec6_AboveFooter_H1{
        font-size: 1.15rem;
    }
    .Dec6_AboveFooter_Item li a{
        font-size: 0.9rem;
    }
    .Dec6_AboveFooter_H2{
        font-size: 1.15rem;
    }
    .Dec6_AboveFooter_H3{
        font-size: 1.15rem;
    }
    
}