@import "../MainCSS.css";

/*
.Dec6_Home_6BoxGL_MainBox_BottomLine {
    width: 100%;
    border-bottom: 1px solid var(--redcolor);
}
.Dec6_Home_6BoxGL_MainBox {
    background-color:var(--whitecolor);
    width: 80%;
    margin: 10px auto;
    display: grid;
    font-family: var(--fontfamily);
    grid-template-rows: 358px 350px ;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;  
}*/
.iconglb{
    padding-bottom: 25px;
}
.fas {
    font-size: 30px;
    color: #54595F;
}
.fa-shopping-cart{
    font-size: 50px;
    color: #54595F;
    
}

.fa-clinic-medical{
    font-size: 50px;
    color: #3a3c44;
}

.fa-recycle{
    font-size: 50px;
    color: #3a3c44;
}

.Dec6_Home_6BoxGL_MainBox_Box3{
    
    grid-column: 3/4;
}
/*
.Dec6_Home_6BoxGL_MainBox_Box1{

    grid-column: 1/2;
}
.Dec6_Home_6BoxGL_MainBox_Box2{

    grid-column: 2/3;
}
.Dec6_Home_6BoxGL_MainBox_Box4{
    
    grid-column: 2/2;
}
.Dec6_Home_6BoxGL_MainBox_Box5{

    grid-column: 2/3;
}
.Dec6_Home_6BoxGL_MainBox_Box6{

    grid-column: 2/4;
}*/


.fa-hand-holding-heart{
    font-size: 50px;
    color: #3a3c44;
}

.fa-space-shuttle{
    font-size: 50px;
    color: #3a3c44;
}

.fa-plane-departure{
    font-size: 50px;
    color: #3a3c44;
}

.Dec6_Home_6BoxGL_MainBox_H{
    font-size: 1.2rem;
    color: var(--redcolor);
    padding-bottom: 15px;
    font-weight: 600;
}
.Dec6_Home_6BoxGL_MainBox_P{
    font-size: 0.9 rem;
    line-height: 26px;
    justify-content: center;
    color:var(--blackcolor);
}

.fas {
    font-size: 42px;
    color: #54595F;
    color: rgb(22, 19, 19,0.8);
}

.Dec6_Home_6BoxGL_MainBox_P_Button {
    width: 180px;
    height: 130px;
    font-size: 1.4rem;
 /*   padding: 2px 4px; */
/*    margin-left: 10px;  */
    color: var(--redcolor);
 /*   border: 2px solid var(--redcolor);  */
    cursor: pointer;
}

