@import "../MainCSS.css";


.D6_Team_Grid {
    font-family: var(--fontfamily);
    color: white;
}
.D6_Team_Grid1_H_Div {
    text-align: center;
    color: var(--redcolor);
    margin: 20px auto;
    margin-top: 40px;
}
.D6_Team_Grid1_H {
    font-size: 2.3rem;
}
.D6_Team_Grid_Div {
    height: 360px;
    max-width: 300px;
    margin: 10px auto;
    text-align: left;
}

.D6_Team_Grid1_ImageK {
    height: 300px;
}

.D6_FE_Team_Grid1_Image {
/*    clip-path: circle();  */
    height: 250px;  
    width: 300px;
}  

.D6_Team_Grid_NameDiv {
    color: var(--redcolor);
    margin-top: 8px;
}

.D6_Team_Grid_NH {
    font-size: 1.2rem;
/*    padding-top: 5px;   */
    transition: transform 1s;
}
.D6_Team_Grid_DH {
    font-size: 1rem;
    font-weight: lighter;
}

.D6_AboutUs_Team_Block_Img_Content {
    position: absolute;
    word-wrap: break-word;
 /*   top: 1510px;  */
    top: 91rem;  
/*    bottom: 7%;   */
    display: block;
    height: 12vh;
    width: 23vw;
    width: 305px;
    height: 70px;
    font-weight: bold;  
    font-size: 1rem;
    color: var(--blackcolor);
    background: transparent;
    transform: scaleY(0);
    transition: transform 0.5s;
}

.D6_Team_Grid_Image_Block  .D6_Team_Grid1_Image{
    transition: transform 2s;
}

.D6_Team_Grid_Image_Block:hover  .D6_Team_Grid1_Image{
    transform: scale(1.1);
    opacity: 0.2;  
}

.D6_Team_Grid_Image_Block:hover  .D6_Team_Grid_NameDiv{
    margin-top: 20px;
}

.D6_Team_Grid_Image_Block:hover .D6_AboutUs_Team_Block_Img_Content{
    transform: scaleY(1);
}

/* Row 2 */
.D6_AboutUs_Team_Block2_Img_Content2 {
    position: absolute;
    word-wrap: break-word;
 /*   top: 1510px;  */
    top: 113.4rem;  
/*    bottom: 7%;   */
    display: block;
    height: 12vh;
    width: 23vw;
    width: 305px;
    height: 70px;
    font-weight: bold;  
    font-size: 1rem;
    color: var(--blackcolor);
    background: transparent;
    transform: scaleY(0);
    transition: transform 0.5s;
}

.D6_Team_Grid_Image_Block2  .D6_Team_Grid2_Image{
    transition: transform 2s;
}

.D6_Team_Grid_Image_Block2:hover  .D6_Team_Grid2_Image{
    transform: scale(1.1);
    opacity: 0.2;  
}

.D6_Team_Grid_Image_Block2:hover  .D6_Team_Grid2_NameDiv{
    margin-top: 20px;
}

.D6_Team_Grid_Image_Block2:hover .D6_AboutUs_Team_Block2_Img_Content2{
    transform: scaleY(1);
}

.D6_Team_Grid2_Image {
    height: 300px;
}

@media (max-width: 960px) {
    .D6_AboutUs_Team_Block_Img_Content {
        top: 89rem;  
    }
    .D6_AboutUs_Team_Block_Img_Content3 {
        top: 113rem;  
    }
    .D6_AboutUs_Team_Block2_Img_Content2 {
        top: 112rem;  
    }
    .D6_Team_Grid_A .D6_Team_Grid_R{
        display: none;
    }
    .D6_Team_Grid2_A {
        display: none;
    }
}

@media (max-width: 600px) {
    .D6_AboutUs_Team_Block_Img_Content2 {
        top: 113rem;  
    }
    .D6_AboutUs_Team_Block_Img_Content3 {
        top: 137rem;  
    }
    .D6_Team_Grid2_Ank {
        display: none;
    }
}

@media (max-width: 450px) {
    .D6_AboutUs_Team_Block_Img_Content {
        top: 88rem;  
    }
    .D6_AboutUs_Team_Block_Img_Content2 {
        top: 111rem;  
    }
    .D6_AboutUs_Team_Block_Img_Content3 {
        top: 135rem;  
    }
    .D6_Team_Grid2_Ank {
        display: none;
    }
}