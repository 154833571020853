

:root{
    --redcolor: #cc0000;
    --fontfamily: "Helvetica", Sans-serif;  
    --fontfamily: 'Questrial', sans-serif; 
    --blackcolor: #000000;
    --whitecolor: #FFFFFF;
  /*  --fontfamily: 'Montserrat', sans-serif; */
    --redcolor2: #9D1B22;
    --greycolorshade : #424242;
}