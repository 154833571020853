@import "../MainCSS.css";

.Dec6_AboutUs_SuccessStory_Block {
    display: grid;
    margin: 50px auto;
    grid-template-rows: 35px 120px 70px;
    grid-template-columns: repeat(3,1fr);
    width: 80%;
    gap: 40px;
    background-color: var(--whitecolor);
    font-family: var(--fontfamily);
}
.Dec6_AboutUs_SuccessStory_Block_H {
    grid-column: 1/-1;
    text-align: center;
    color: var(--redcolor);
    font-size: 1.6rem;
}
.Dec6_AboutUs_SuccessStory_Block_P1 {
    font-size: 0.9rem;
    line-height: 1.5rem;
}
.Dec6_AboutUs_SuccessStory_Block_P2 {
    font-size: 0.9rem;
    line-height: 1.5rem;
}
.Dec6_AboutUs_SuccessStory_Block_P3 {
    font-size: 0.9rem;
    line-height: 1.5rem;
}
.Dec6_AboutUs_SuccessStory_Block_Img {
    clip-path: circle();
    width: 42px;
}

.Dec6_AboutUs_SuccessStory_Block_Img_Mark {
    margin-top: -5px;
}  

.Dec6_AboutUs_SuccessStory_Block_Img_H_Name {
    margin-left: 60px;
    margin-top: -39px;
    color: var(--redcolor);
    font-size: 0.9rem;
}
.Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Mark {
    margin-left: 60px;
    margin-top: -50px;
    color: var(--redcolor);
    font-size: 0.9rem;
}
.Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Liam {
    margin-left: 60px;
    margin-top: -45px;
    color: var(--redcolor);
    font-size: 0.9rem;
}
.Dec6_AboutUs_SuccessStory_Block_Img_H_Desig {
    margin-left: 60px;
    color: var(--redcolor);
    font-size: 0.8rem;
    font-weight: lighter;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_AboutUs_SuccessStory_Block_Img {
        margin-top: 20px;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_Mark {
        margin-top: 12px;
    }  
    .Dec6_AboutUs_SuccessStory_Block_Img_H_Name {
        margin-top: -34px;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Liam {
        margin-top: -41px;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Mark {
        margin-top: -45px;
    }
}

@media (max-width: 900px) {
    .Dec6_AboutUs_SuccessStory_Block {
        gap: 20px;
        width: 90%;
    }
    .Dec6_AboutUs_SuccessStory_Block_H {
        font-size: 1.3rem;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img {
        margin-top: 45px;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_Mark {
        margin-top: 35px;
    } 
    .Dec6_AboutUs_SuccessStory_Block_Img_H_Name {
        margin-top: -34px;
    }
 /*   .Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Liam {
        margin-top: -41px;
    }*/
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_AboutUs_SuccessStory_Block {
        gap: 20px;
        width: 95%;
    }
    .Dec6_AboutUs_SuccessStory_Block_H {
        font-size: 1.3rem;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img {
        margin-top: 40px;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_H_Name {
        margin-top: -34px;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_Mark {
        margin-top: 35px;
    } 
    .Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Liam {
        margin-top: -41px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P1 {
        font-size: 0.87rem;
        line-height: 1.5rem;
    }
    .Dec6_AboutUs_SuccessStory_Block_P2 {
        font-size: 0.87rem;
        line-height: 1.5rem;
    }
    .Dec6_AboutUs_SuccessStory_Block_P3 {
        font-size: 0.87rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 700px) {
    .Dec6_AboutUs_SuccessStory_Block {
        display: grid;
        margin: 50px auto;
        grid-template-rows: 35px 120px 70px;
        grid-template-columns: repeat(2,1fr);
        width: 80%;
        gap: 40px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P3 {
        display: none;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img3_Block {
        display: none;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_Mark {
        margin-top: 34px;
    } 
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Dec6_AboutUs_SuccessStory_Block {
        display: grid;
        margin: 50px auto;
        grid-template-rows: 35px 120px 70px;
        grid-template-columns: repeat(2,1fr);
        width: 90%;
        gap: 40px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P3 {
        display: none;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img3_Block {
        display: none;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 490px) {
    .Dec6_AboutUs_SuccessStory_Block {
        display: grid;
        margin: 50px auto;
        grid-template-rows: 35px 120px 70px;
        grid-template-columns: repeat(2,1fr);
        width: 98%;
        row-gap: 40px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P3 {
        display: none;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img3_Block {
        display: none;
    }
    .Dec6_AboutUs_SuccessStory_Block_Img_Mark {
        margin-top: 35px;
    } 
}

@media (max-width: 450px) {
    .Dec6_AboutUs_SuccessStory_Block {
        display: grid;
        margin-left: 2px;
        grid-template-rows: 35px 120px 70px;
        grid-template-columns: 1fr 1fr;
        width: 99%;
        row-gap: 30px;
        column-gap: 5px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P1 {
        font-size: 0.77rem;
        line-height: 1.5rem;
        margin-bottom: 40px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P2 {
        font-size: 0.77rem;
        line-height: 1.5rem;
        margin-bottom: 40px;
    }
}

@media (max-width: 380px) {
    .Dec6_AboutUs_SuccessStory_Block {
        display: grid;
        margin-left: 5px;
        grid-template-rows: 35px 120px 70px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        row-gap: 30px;
        column-gap: 10px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P1 {
        font-size: 0.74rem;
        line-height: 1.2rem;
        margin-bottom: 40px;
    }
    .Dec6_AboutUs_SuccessStory_Block_P2 {
        font-size: 0.74rem;
        line-height: 1.2rem;
        margin-bottom: 40px;
    }
}

