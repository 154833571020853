.bg {
    width: 100%;
    height: 420px;
    background: transparent;
 /*   margin-top: 320px; */
}

.Dec6_Industries_B2B_Cont_MainBlock {
    display: grid;
    width: 80%;
    margin:auto;
    margin-top: -250px;
    justify-content: space-evenly;
    grid-template-rows: 350px;
    grid-template-columns: repeat(3,1fr);
    background-color: #cc0000;
}

.Dec6_Industries_B2B_Grid {
    margin-top: -240px;
    
}
.Dec6_Industries_B2B_Cont {
    padding-top: 100px;
}
.Dec6_Industries_B2B_Cont_Paper {
    max-width: 300px;
    width: 100%;
    height: 350px;
    margin: 0px auto;
}

.Dec6_Industries_B2B_Cont_Paper_Box {
    text-align: center;
    padding-top: 20px;
 /*   transition: transform 1s;  */
}

.Dec6_Industries_B2B_Cont_Typ1 {
    font-weight: 900;
    font-size: 6rem;
    color: var(--redcolor);
}

.Dec6_Industries_B2B_Cont_Typ2 {
    padding-top: 10px;
    font-weight: 600;
}

Button {
    background-color: #cc0000;
    color: white;
    transition: transform 1s;  
}

Button:hover {
    background-color:white;
    color: #cc0000;
    border: 1px solid #cc0000;
    transition: transform 1s;  
}

/*
.Dec6_Industries_B2B_Cont_Paper_Box:hover {
    transform: scale(1.2);
    padding-top: -60px;
    background-color: #cc0000;
    color:white;
    width: 300px;
    height: 500px;
 /*   opacity: 0.2; 
}

.Dec6_Industries_B2B_Cont_Paper_Box:hover .Dec6_Industries_B2B_Cont_Typ1 {
    color: var(--whitecolor);
}*/


