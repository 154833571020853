@import "../MainCSS.css";

.Dec6_JoinUs_ECS2_Block {
    background-color: var(--whitecolor);
    margin-top: 50px ;
 /*   padding-left: 50px;  */
    font-family: var(--fontfamily);
    text-align: center;
 /*   width: 100%; */
    height: 80px;
}
/*
.Dec6_JoinUs_ECS_Block_Cont1 {
    text-align: center;
}
.Dec6_JoinUs_ECS_Block_Cont1_Paper {
    width: 780px;
    height: 180px;
}
.Dec6_JoinUs_ECS_Block_Cont1_Paper_Box {
    padding-top: 60px;
}*/
.Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
    color: var(--redcolor);
    font-weight: 500;
    font-size: 2.95rem;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
        font-size: 2.7rem;
    }
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
        font-size: 2.5rem;
    }
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
        font-size: 2.4rem;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 560px) {
    .Dec6_JoinUs_ECS2_Block {
        margin-top: 50px ;
        height: 40px;
    }
    .Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .Dec6_JoinUs_ECS2_Block {
        margin-top: 50px ;
        height: 30px;
    }
    .Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
        font-size: 1.6rem;
    }
}

@media (max-width: 380px) {
    .Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H {
        font-size: 1.5rem;
    }
}