@import "../MainCSS.css";

.Dec6_6BGL_MainBox_BottomLine {
    width: 100%;
    
  /*  border-bottom: 1px solid var(--redcolor);  */
}
.Dec6_6BGL_MainBox {
    background-color:var(--whitecolor);
    width: 80%;
    padding-left: 30px;
    padding-right: 10px;
    display: grid;
    font-family: var(--fontfamily);
    grid-template-rows: 230px 300px ;
    grid-template-columns: 1fr 1fr 1fr;
}
.Dec6_6BGL_MainBox_Box1{
    padding-right: 10px !important;
    padding-bottom: 30px !important;
    padding-left: 10px !important;
}
.iconglb{
    padding-bottom: 25px;
}
.fas {
    font-size: 30px;
    color: #54595F;
}
.fa-shopping-cart{
    font-size: 50px;
    color: #54595F;
}

.Dec6_6BGL_MainBox_Button {
    border: none;
    color: var(--graycolorshade);
    color: var(--redcolor);
    background-color: white;
    cursor: pointer;
    font-size: 0.9rem;
    padding-top: 5px ;
}

.Dec6_6BGL_MainBox_Button:hover {
    border: none;
    border-bottom: 1px solid black;
    padding-bottom: 5px ;
    color: var(--redcolor);
    background-color: white;
}

.fa-clinic-medical{
    font-size: 50px;
    color: #3a3c44;
}

.fa-recycle{
    font-size: 50px;
    color: #3a3c44;
}

.Dec6_6BGL_MainBox_Box3{
    
    grid-column: 3/4;
}
.fa-hand-holding-heart{
    font-size: 50px;
    color: #3a3c44;
}

.fa-space-shuttle{
    font-size: 50px;
    color: #3a3c44;
}

.fa-plane-departure{
    font-size: 50px;
    color: #3a3c44;
}

.Dec6_6BGL_MainBox_H{
    font-size: 1.2rem;
    color: var(--redcolor);
    padding-bottom: 15px;
    font-weight: 600;
}
.Dec6_6BGL_MainBox_P{
    font-size: 0.9 rem;
    line-height: 26px;
    justify-content: center;
    color:var(--blackcolor);
}

.fas {
    font-size: 42px;
    color: #54595F;
    color: rgb(22, 19, 19,0.8);
}

.Dec6_6BGL_MainBox_P_Button {
    width: 180px;
    height: 130px;
    font-size: 1.4rem;
 /*   padding: 2px 4px; */
/*    margin-left: 10px;  */
    color: var(--redcolor);
 /*   border: 2px solid var(--redcolor);  */
    cursor: pointer;
}

@media(max-width: 768px)
{
   
    .Dec6_6BGL_MainBox {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
    }
    .Dec6_6BGL_MainBox_P{
        line-height: 23px;
        padding-bottom: 5px;
        display: block !important;
    }
    .Dec6_6BGL_MainBox_H{
        display: block !important; 
    }
    .iconglb{
        padding-top: 45px !important;
    }
    .fa-shopping-cart{
        padding-top: 5px;
    }
}


/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
    .Dec6_6BGL_MainBox {
        display: block;

    }
    .Dec6_6BGL_MainBox_P{
        line-height: 23px;
        padding-bottom: 5px;
    }
    .iconglb{
        padding-top: 40px;
    }
    .fa-shopping-cart{
        padding-top: 5px;
    }
}



/* smartphones, landscape i-phone */
