@import "../MainCSS.css";

.Dec6_TAC_Div {
    height: 2600px;
}
.Dec6_TAC_Grid {
    font-family: var(--fontfamily);
    height: 2500px;
    margin-top: 110px;
    padding-left: 100px;
    padding-right: 90px;
}
.Dec6_TAC_Grid1_MH {
    color: var(--redcolor);
    font-size: 1.7rem;
    padding-top: 30px;
}
.Dec6_TAC_Grid_H {
    font-weight: bold;
}
.Dec6_TAC_Grid_P {
    font-size: 1rem;
    line-height: 22px;
}
.Dec6_TAC_List1 {
 /*   list-style: none;  */
    font-size: 0.8rem;
    padding-left: 50px;
}
.Dec6_TAC_Grid_Link {
    text-decoration: none;
    font-size: 1rem;
    transition: transform 1sec;
}

.Dec6_TAC_Grid_Link:hover {
    color: var(--redcolor);
}
.Dec6_TAC_Grid_List {
    padding-left: 50px;
}


  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (max-width: 992px) {
    .Dec6_TAC_Div {
        height: 2800px;
    }
    .Dec6_TAC_Grid {
        padding-left: 80px;
        padding-right: 80px;
        height: 2700px;
    }
  } 

  @media only screen and (max-width: 900px) {
    .Dec6_TAC_Div {
        height: 3000px;
    }
    .Dec6_TAC_Grid {
        padding-left: 80px;
        padding-right: 80px;
        height: 2900px;
    }
  } 

  @media only screen and (max-width: 830px) {
    .Dec6_TAC_Div {
        height: 3300px;
    }
    .Dec6_TAC_Grid {
        padding-left: 80px;
        padding-right: 80px;
        height: 3200px;
    }
  } 

  @media only screen and (max-width: 750px) {
    .Dec6_TAC_Div {
        height: 3600px;
    }
    .Dec6_TAC_Grid {
        padding-left: 80px;
        padding-right: 80px;
        height: 3500px;
    }
  }

  @media only screen and (max-width: 690px) {
    .Dec6_TAC_Div {
        height: 3900px;
    }
    .Dec6_TAC_Grid {
        padding-left: 50px;
        padding-right: 50px;
        height: 3800px;
    }
  }

  @media only screen and (max-width: 590px) {
    .Dec6_TAC_Div {
        height: 4200px;
    }
    .Dec6_TAC_Grid {
        padding-left: 30px;
        padding-right: 30px;
        height: 4100px;
    }
  }
  @media only screen and (max-width: 510px) {
    .Dec6_TAC_Div {
        height: 4500px;
    }
    .Dec6_TAC_Grid {
        padding-left: 30px;
        padding-right: 30px;
        height: 4400px;
    }
  }
  @media only screen and (max-width: 473px) {
    .Dec6_TAC_Div {
        height: 4900px;
    }
    .Dec6_TAC_Grid {
        padding-left: 20px;
        padding-right: 20px;
        height: 4800px;
    }
  }

  @media only screen and (max-width: 420px) {
    .Dec6_TAC_Div {
        height: 5300px;
    }
    .Dec6_TAC_Grid {
        padding-left: 20px;
        padding-right: 20px;
        height: 5200px;
    }
  }

  @media only screen and (max-width: 390px) {
    .Dec6_TAC_Div {
        height: 5700px;
    }
    .Dec6_TAC_Grid {
        padding-left: 10px;
        padding-right: 10px;
        height: 5600px;
    }
  }
  @media only screen and (max-width: 342px) {
    .Dec6_TAC_Div {
        height: 6100px;
    }
    .Dec6_TAC_Grid {
        padding-left: 10px;
        padding-right: 10px;
        height: 6000px;
    }
    .Dec6_TAC_Grid1_MH {
        color: var(--redcolor);
        font-size: 1.3rem;
        padding-top: 30px;
    }
    
    .Dec6_TAC_Grid_P {
        font-size: 0.87rem;
        line-height: 22px;
    }
    .Dec6_TAC_List1 {
     /*   list-style: none;  */
        font-size: 0.76rem;
        padding-left: 50px;
    }
    .Dec6_TAC_Grid_Link {
        text-decoration: none;
        font-size: 0.87rem;
        transition: transform 1sec;
    }
  }
  @media only screen and (max-width: 300px) {
    .Dec6_TAC_Div {
        height: 6600px;
    }
    .Dec6_TAC_Grid {
        padding-left: 10px;
        padding-right: 10px;
        height: 6400px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }