@import "../MainCSS.css";

.Dec6_PP_Block {
    height: 2100px;
}
.Dec6_PrivacyPolicy_Block_Grid {
    height: 2010px;
    margin-top: 110px;
    font-family: var(--fontfamily);
    padding-left: 100px;
    padding-right: 90px;
 /*   background-color: antiquewhite;  */
}
.Dec6_PrivacyPolicy_Block {
    margin-top: 110px;
    width: 100%;
    height: 1000px;
    font-family: var(--fontfamily);
}

.Dec6_PrivacyPolicy_Block_MH {
    padding-top: 40px;
    font-size: 1.8rem;
    color: var(--redcolor);
}

.Dec6_PrivacyPolicy_Block_Grid_BoldText {
    font-weight: bold;
}
.Dec6_PP_Grid_Link {
    text-decoration: none;
    transition: transform 2sec;
}
.Dec6_PP_Grid_Link:hover {
    color: var(--redcolor);
}
.Dec6_PrivacyPolicy_Block_P {
    font-size: 1rem;
    line-height: 22px;
}

.Dec6_PrivacyPolicy_Block_P1 {
    font-size: 1rem;
    line-height: 22px;
}
.Dec6_PrivacyPolicy_Block_Grid_UL {
    padding-left: 60px;
}

/*
.Dec6_PrivacyPolicy_Block_OL {
    padding: 20px 80px;
    padding-left: 100px;
    padding-right: 100px;
    font-size: 1rem;
    font-weight: bold;
}

.Dec6_PrivacyPolicy_Block_UL {
    list-style: none;
    padding-top: 30px;
    padding-left: 0px;
    padding-right: 100px;
    font-size: 0.98rem;
    font-weight: lighter;
}
*/
.Dec6_PrivacyPolicy_Block_P3 {
    font-weight: lighter;
}

@media only screen and (max-width: 900px) {
    .Dec6_PP_Block {
        height: 2200px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 2100px;
    }
  }

  @media only screen and (max-width: 800px) {
    .Dec6_PP_Block {
        height: 2400px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 2300px;
        padding-left: 70px;
        padding-right: 70px;
    }
  }

  @media only screen and (max-width: 700px) {
    .Dec6_PP_Block {
        height: 2600px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 2500px;
        padding-left: 50px;
        padding-right: 50px;
    }
  }

  @media only screen and (max-width: 600px) {
    .Dec6_PP_Block {
        height: 2800px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 2700px;
        padding-left: 30px;
        padding-right: 30px;
    }
  }
  @media only screen and (max-width: 530px) {
    .Dec6_PP_Block {
        height: 3200px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 3100px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }
  @media only screen and (max-width: 410px) {
    .Dec6_PP_Block {
        height: 3500px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 3400px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }
  @media only screen and (max-width: 380px) {
    .Dec6_PP_Block {
        height: 3800px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 3700px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }
  @media only screen and (max-width: 350px) {
    .Dec6_PP_Block {
        height: 4200px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 4100px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }
  @media only screen and (max-width: 300px) {
    .Dec6_PP_Block {
        height: 4900px;
    }
    .Dec6_PrivacyPolicy_Block_Grid {
        height: 4750px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }