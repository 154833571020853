@import "../MainCSS.css";
/*
html{
  font-size: 62.5%;

  /* 1rem = 16px
     16px = 100%
     10px = 62.5%
     1rem = 10px
  */



* {
  margin: 0;
  padding: 0;
  font-family: var(--fontfamily);
}

.Dec6_HeaderRL_Container1 {
  background-color: var(--redcolor);
  width: 100%;
  height: 5px;
}

.D6_Navbar_Items {
    position: fixed;
    width: 100%;
    background-color: #fefefe;
  /*padding-right: 88px;*/
    height: 110px; 
    color: var(--blackcolor);
    background: white;
  /* padding-left: 88px;*/
    z-index: 4;  
    border-bottom: 1px solid #c3c1c1;
}

.D6_Navbar_Items_Image {
  float: left;
  height: 60px;
  width: 180px;
  margin: 24px 130px;
}

.D6_Navbar_Items_List {
  overflow: auto;
  margin-right: 80px;
}

.D6_Navbar_Items li {
  float: right;
  list-style: none;
  margin: 48px 15px;
}

.D6_Navbar_Items li a {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: color 0.3sec;
  transition: color 0.3sec;
  color: var(--blackcolor);
}

.D6_Navbar_Items li a:hover {
  color: var(--redcolor);
}

.D6_Navbar_Items.active {
  background-color: var(--whitecolor) ;
  color: var(--blackcolor) !important;
  margin-top: -1px;
}

/* @import "../ResponsiveCode/Responsive_CSS.css";  */

@media(max-width: 768px)
{
  .D6_Navbar_Items{
    width: 48%;
  }
  .D6_Navbar_Items_List {
    display: none;
  }
}