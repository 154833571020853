@import "../MainCSS.css";
/*
.Dec6_STTop_Block {
    position: fixed;
    bottom: 12px;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--redcolor);
    color: var(--whitecolor);
    z-index: 1;
}
.Dec6_STTop_Icon {
    margin-top: 5px;
    margin-left: 6px;
    color: white;
    font-size: 27px;
    animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop {
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);
    }
}
*/

.Dec6_ST_Div {
  display: flex;
  justify-content: flex-end;
  margin: 0px auto;  
  margin-right: 10px;
}
  
  .scrollTop {
    position: fixed; 
    width: 100%;
    width: 40px;
    bottom: 20px;
    align-items: center;
    color: var(--redcolor);
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
   /* transition: opacity 0.4s; */
    animation: Top 0.5s alternate ease infinite;
 /*   margin-left: 1300px;  */
  }

  @keyframes Top {
    from{
        transform: translateY(2px);
    }
    to{
        transform: translateY(-1px);
    }
}
  
  .scrollTop:hover{
    color: var(--redcolor2);
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }