.Dec6_ContactUs_Map_Block {
    width: 100%;
    height: 390px;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 450px) {
}