
.DialogBox_Div1 {
    width: 330px;
    max-width: 100%;
    height: 170px;
    margin: 0 auto;
    position: fixed;
    left: 40%;
    top:50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    background-color: #eee;
    padding: 10px 20px 40px;
    padding: 10px;
    padding-top: 5px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.PStyle {
    font-size: 0.96rem;
}

@media (max-width: 600px) {
    .DialogBox_Div1 {
        position: fixed;
        left: 50%;
        top: 75%;
    }
    .PStyle {
        font-size: 0.9rem;
    }
}