@import "../MainCSS.css";

*{
    margin: 0;
    padding: 0;
}

.Dec6_WWA_Items{
    background-color: var(--whitecolor);
    color:var(--blackcolor);
    height: 230px;
    width: 100%;
    box-sizing: border-box;
    font-family: var(-fontfamily);
    padding-top: 10px;
    text-align: center;
    align-items: center;
}

.Dec6_WWA_Items_Div {
    width: 76vw;
    height:18vh;
    display: inline-block;
    border-bottom: 1px solid var(--blackcolor);

}
.Dec6_WWA_Items_P1{
    color: var(--redcolor);
    font-size: 2.2rem;
}
.Dec6_WWA_Items_P2{
    font-size: 1.33rem;
    padding-top: 50px;
    color:var(--blackcolor);
}
/*  ---Horizontal Line---  */

.Dec6_WWA_Items_HR {
    border-top: 1px solid black;
    width: 68rem;
    margin-left: 130px;
    margin-top: 80px;
  /*  margin-left: 8.2rem;
    margin-top: 5rem;   */
}


/*
@media(max-width: 768px)
{
    .Dec6_WWA_Items{
        width: 47%;
        margin-top: 70px;
        width: 100%;
        width: 99.7%;
    }
 /*   .Dec6_WWA_Items_P1{
        font-size: 2.4rem;
    }
    .Dec6_WWA_Items_P2{
        font-size: 1.33rem;
    }
    .Dec6_WWA_Items_HR {
        width: 96%;
        margin-top: 50px;
    }
}*/

/* big-landscape tablets, low resolution laptop and desktop */
@media (min-width: 1200px) {

}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    
}

/* smartphones, landscape i-phone */
@media (max-width: 650px) {
    .Dec6_WWA_Items_Div {
        width: 78vw;
        height: 28vh;
    }
    .Dec6_WWA_Items_P1{
        color: var(--redcolor);
        font-size: 2rem;
    }
    .Dec6_WWA_Items_P2{
        font-size: 1.15rem;
        padding-top: 50px;
        color:var(--blackcolor);
    }
}

@media (max-width: 550px) {
    .Dec6_WWA_Items_Div {
        width: 84vw;
        height: 28vh;
    }
    .Dec6_WWA_Items_P1{
        color: var(--redcolor);
        font-size: 1.8rem;
    }
    .Dec6_WWA_Items_P2{
        font-size: 1.15rem;
        padding-top: 50px;
        color:var(--blackcolor);
    }
}

@media (max-width: 550px) {
    .Dec6_WWA_Items{
        height: 190px;
    }
    .Dec6_WWA_Items_Div {
        width: 86vw;
        height: 24vh;
    }
    .Dec6_WWA_Items_P1{
        color: var(--redcolor);
        font-size: 1.5rem;
    }
    .Dec6_WWA_Items_P2{
        font-size: 1.03rem;
        padding-top: 30px;
        line-height: 20px;
        color:var(--blackcolor);
    }
}

@media (max-width: 380px) {
    .Dec6_WWA_Items{
        height: 190px;
    }
    .Dec6_WWA_Items_Div {
        width: 88vw;
        height: 24vh;
    }
    .Dec6_WWA_Items_P1{
        color: var(--redcolor);
        font-size: 1.4rem;
    }
    .Dec6_WWA_Items_P2{
        font-size: 0.95rem;
        padding-top: 30px;
        line-height: 20px;
        color:var(--blackcolor);
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 480px) {
    
}

@media (max-width: 450px) {
}